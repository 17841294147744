import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  json: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

export type AcceptInvitationOutput = {
  __typename?: 'AcceptInvitationOutput';
  group_id: Scalars['uuid'];
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CreateGroupInvitationLinkOutput = {
  __typename?: 'CreateGroupInvitationLinkOutput';
  invitation_id: Scalars['uuid'];
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type MakeForecastOutput = {
  __typename?: 'MakeForecastOutput';
  forecast?: Maybe<Forecast>;
  matchId: Scalars['uuid'];
  profileId: Scalars['uuid'];
};

export type RegisterGroupOtput = {
  __typename?: 'RegisterGroupOtput';
  group?: Maybe<Group>;
  groupId: Scalars['uuid'];
};

export type RegisterGroupOutput = {
  __typename?: 'RegisterGroupOutput';
  groupId: Scalars['uuid'];
};

export type RegisterPseudoOutput = {
  __typename?: 'RegisterPseudoOutput';
  profile?: Maybe<Profile>;
  profile_id: Scalars['uuid'];
};

export type SetUserAdminStateOutput = {
  __typename?: 'SetUserAdminStateOutput';
  userId: Scalars['uuid'];
};

export type SetUserModeratorStateOutput = {
  __typename?: 'SetUserModeratorStateOutput';
  userId: Scalars['uuid'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type UpdateGroupInfoOutput = {
  __typename?: 'UpdateGroupInfoOutput';
  group?: Maybe<Group>;
  groupId: Scalars['uuid'];
};

/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "cup" */
export type Cup = {
  __typename?: 'cup';
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  groups: Array<Group>;
  /** An aggregated array relationship */
  groups_aggregate: Group_Aggregate;
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  label: Scalars['String'];
  /** An array relationship */
  matchdays: Array<Matchday>;
  /** An aggregated array relationship */
  matchdays_aggregate: Matchday_Aggregate;
  /** An array relationship */
  pools: Array<Pool>;
  /** An aggregated array relationship */
  pools_aggregate: Pool_Aggregate;
  /** An array relationship */
  profile_points: Array<Profile_Points>;
  /** An aggregated array relationship */
  profile_points_aggregate: Profile_Points_Aggregate;
  /** An array relationship */
  profiles: Array<Profile>;
  /** An aggregated array relationship */
  profiles_aggregate: Profile_Aggregate;
  /** An array relationship */
  team_cups: Array<Team_Cup>;
  /** An aggregated array relationship */
  team_cups_aggregate: Team_Cup_Aggregate;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "cup" */
export type CupGroupsArgs = {
  distinct_on?: Maybe<Array<Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Order_By>>;
  where?: Maybe<Group_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Order_By>>;
  where?: Maybe<Group_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupMatchdaysArgs = {
  distinct_on?: Maybe<Array<Matchday_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Matchday_Order_By>>;
  where?: Maybe<Matchday_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupMatchdays_AggregateArgs = {
  distinct_on?: Maybe<Array<Matchday_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Matchday_Order_By>>;
  where?: Maybe<Matchday_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupPoolsArgs = {
  distinct_on?: Maybe<Array<Pool_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Order_By>>;
  where?: Maybe<Pool_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupPools_AggregateArgs = {
  distinct_on?: Maybe<Array<Pool_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Order_By>>;
  where?: Maybe<Pool_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupProfile_PointsArgs = {
  distinct_on?: Maybe<Array<Profile_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Points_Order_By>>;
  where?: Maybe<Profile_Points_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupProfile_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Points_Order_By>>;
  where?: Maybe<Profile_Points_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupProfilesArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupProfiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupTeam_CupsArgs = {
  distinct_on?: Maybe<Array<Team_Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Cup_Order_By>>;
  where?: Maybe<Team_Cup_Bool_Exp>;
};

/** columns and relationships of "cup" */
export type CupTeam_Cups_AggregateArgs = {
  distinct_on?: Maybe<Array<Team_Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Cup_Order_By>>;
  where?: Maybe<Team_Cup_Bool_Exp>;
};

/** aggregated selection of "cup" */
export type Cup_Aggregate = {
  __typename?: 'cup_aggregate';
  aggregate?: Maybe<Cup_Aggregate_Fields>;
  nodes: Array<Cup>;
};

/** aggregate fields of "cup" */
export type Cup_Aggregate_Fields = {
  __typename?: 'cup_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cup_Max_Fields>;
  min?: Maybe<Cup_Min_Fields>;
};

/** aggregate fields of "cup" */
export type Cup_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cup" */
export type Cup_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Cup_Max_Order_By>;
  min?: Maybe<Cup_Min_Order_By>;
};

/** input type for inserting array relation for remote table "cup" */
export type Cup_Arr_Rel_Insert_Input = {
  data: Array<Cup_Insert_Input>;
  on_conflict?: Maybe<Cup_On_Conflict>;
};

/** Boolean expression to filter rows from the table "cup". All fields are combined with a logical 'AND'. */
export type Cup_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cup_Bool_Exp>>>;
  _not?: Maybe<Cup_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cup_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  groups?: Maybe<Group_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isActive?: Maybe<Boolean_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  matchdays?: Maybe<Matchday_Bool_Exp>;
  pools?: Maybe<Pool_Bool_Exp>;
  profile_points?: Maybe<Profile_Points_Bool_Exp>;
  profiles?: Maybe<Profile_Bool_Exp>;
  team_cups?: Maybe<Team_Cup_Bool_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "cup" */
export enum Cup_Constraint {
  /** unique or primary key constraint */
  CupLabelKey = 'cup_label_key',
  /** unique or primary key constraint */
  CupsPkey = 'cups_pkey',
}

/** input type for inserting data into table "cup" */
export type Cup_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  groups?: Maybe<Group_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  matchdays?: Maybe<Matchday_Arr_Rel_Insert_Input>;
  pools?: Maybe<Pool_Arr_Rel_Insert_Input>;
  profiles?: Maybe<Profile_Arr_Rel_Insert_Input>;
  team_cups?: Maybe<Team_Cup_Arr_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Cup_Max_Fields = {
  __typename?: 'cup_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "cup" */
export type Cup_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cup_Min_Fields = {
  __typename?: 'cup_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "cup" */
export type Cup_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "cup" */
export type Cup_Mutation_Response = {
  __typename?: 'cup_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Cup>;
};

/** input type for inserting object relation for remote table "cup" */
export type Cup_Obj_Rel_Insert_Input = {
  data: Cup_Insert_Input;
  on_conflict?: Maybe<Cup_On_Conflict>;
};

/** on conflict condition type for table "cup" */
export type Cup_On_Conflict = {
  constraint: Cup_Constraint;
  update_columns: Array<Cup_Update_Column>;
  where?: Maybe<Cup_Bool_Exp>;
};

/** ordering options when selecting data from "cup" */
export type Cup_Order_By = {
  createdAt?: Maybe<Order_By>;
  groups_aggregate?: Maybe<Group_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  isActive?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  matchdays_aggregate?: Maybe<Matchday_Aggregate_Order_By>;
  pools_aggregate?: Maybe<Pool_Aggregate_Order_By>;
  profile_points_aggregate?: Maybe<Profile_Points_Aggregate_Order_By>;
  profiles_aggregate?: Maybe<Profile_Aggregate_Order_By>;
  team_cups_aggregate?: Maybe<Team_Cup_Aggregate_Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "cup" */
export type Cup_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "cup" */
export enum Cup_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "cup" */
export type Cup_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "cup" */
export enum Cup_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** columns and relationships of "forecast" */
export type Forecast = {
  __typename?: 'forecast';
  createdAt: Scalars['timestamptz'];
  estimatedAway: Scalars['Int'];
  estimatedHome: Scalars['Int'];
  /** An object relationship */
  forecastsStatus?: Maybe<Forecast_Status>;
  /** An object relationship */
  match: Match;
  matchId: Scalars['uuid'];
  /** An object relationship */
  profile: Profile;
  profileId: Scalars['uuid'];
  status?: Maybe<Forecast_Status_Enum>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "forecast" */
export type Forecast_Aggregate = {
  __typename?: 'forecast_aggregate';
  aggregate?: Maybe<Forecast_Aggregate_Fields>;
  nodes: Array<Forecast>;
};

/** aggregate fields of "forecast" */
export type Forecast_Aggregate_Fields = {
  __typename?: 'forecast_aggregate_fields';
  avg?: Maybe<Forecast_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Forecast_Max_Fields>;
  min?: Maybe<Forecast_Min_Fields>;
  stddev?: Maybe<Forecast_Stddev_Fields>;
  stddev_pop?: Maybe<Forecast_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Forecast_Stddev_Samp_Fields>;
  sum?: Maybe<Forecast_Sum_Fields>;
  var_pop?: Maybe<Forecast_Var_Pop_Fields>;
  var_samp?: Maybe<Forecast_Var_Samp_Fields>;
  variance?: Maybe<Forecast_Variance_Fields>;
};

/** aggregate fields of "forecast" */
export type Forecast_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Forecast_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "forecast" */
export type Forecast_Aggregate_Order_By = {
  avg?: Maybe<Forecast_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Forecast_Max_Order_By>;
  min?: Maybe<Forecast_Min_Order_By>;
  stddev?: Maybe<Forecast_Stddev_Order_By>;
  stddev_pop?: Maybe<Forecast_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Forecast_Stddev_Samp_Order_By>;
  sum?: Maybe<Forecast_Sum_Order_By>;
  var_pop?: Maybe<Forecast_Var_Pop_Order_By>;
  var_samp?: Maybe<Forecast_Var_Samp_Order_By>;
  variance?: Maybe<Forecast_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "forecast" */
export type Forecast_Arr_Rel_Insert_Input = {
  data: Array<Forecast_Insert_Input>;
  on_conflict?: Maybe<Forecast_On_Conflict>;
};

/** aggregate avg on columns */
export type Forecast_Avg_Fields = {
  __typename?: 'forecast_avg_fields';
  estimatedAway?: Maybe<Scalars['Float']>;
  estimatedHome?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "forecast" */
export type Forecast_Avg_Order_By = {
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "forecast". All fields are combined with a logical 'AND'. */
export type Forecast_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Forecast_Bool_Exp>>>;
  _not?: Maybe<Forecast_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Forecast_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  estimatedAway?: Maybe<Int_Comparison_Exp>;
  estimatedHome?: Maybe<Int_Comparison_Exp>;
  forecastsStatus?: Maybe<Forecast_Status_Bool_Exp>;
  match?: Maybe<Match_Bool_Exp>;
  matchId?: Maybe<Uuid_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profileId?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<Forecast_Status_Enum_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "forecast" */
export enum Forecast_Constraint {
  /** unique or primary key constraint */
  ForecastsPkey = 'forecasts_pkey',
}

/** input type for incrementing integer column in table "forecast" */
export type Forecast_Inc_Input = {
  estimatedAway?: Maybe<Scalars['Int']>;
  estimatedHome?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "forecast" */
export type Forecast_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  estimatedAway?: Maybe<Scalars['Int']>;
  estimatedHome?: Maybe<Scalars['Int']>;
  forecastsStatus?: Maybe<Forecast_Status_Obj_Rel_Insert_Input>;
  match?: Maybe<Match_Obj_Rel_Insert_Input>;
  matchId?: Maybe<Scalars['uuid']>;
  profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  profileId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Forecast_Status_Enum>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Forecast_Max_Fields = {
  __typename?: 'forecast_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  estimatedAway?: Maybe<Scalars['Int']>;
  estimatedHome?: Maybe<Scalars['Int']>;
  matchId?: Maybe<Scalars['uuid']>;
  profileId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "forecast" */
export type Forecast_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
  matchId?: Maybe<Order_By>;
  profileId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Forecast_Min_Fields = {
  __typename?: 'forecast_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  estimatedAway?: Maybe<Scalars['Int']>;
  estimatedHome?: Maybe<Scalars['Int']>;
  matchId?: Maybe<Scalars['uuid']>;
  profileId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "forecast" */
export type Forecast_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
  matchId?: Maybe<Order_By>;
  profileId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "forecast" */
export type Forecast_Mutation_Response = {
  __typename?: 'forecast_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Forecast>;
};

/** input type for inserting object relation for remote table "forecast" */
export type Forecast_Obj_Rel_Insert_Input = {
  data: Forecast_Insert_Input;
  on_conflict?: Maybe<Forecast_On_Conflict>;
};

/** on conflict condition type for table "forecast" */
export type Forecast_On_Conflict = {
  constraint: Forecast_Constraint;
  update_columns: Array<Forecast_Update_Column>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** ordering options when selecting data from "forecast" */
export type Forecast_Order_By = {
  createdAt?: Maybe<Order_By>;
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
  forecastsStatus?: Maybe<Forecast_Status_Order_By>;
  match?: Maybe<Match_Order_By>;
  matchId?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profileId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "forecast" */
export type Forecast_Pk_Columns_Input = {
  matchId: Scalars['uuid'];
  profileId: Scalars['uuid'];
};

/** select columns of table "forecast" */
export enum Forecast_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EstimatedAway = 'estimatedAway',
  /** column name */
  EstimatedHome = 'estimatedHome',
  /** column name */
  MatchId = 'matchId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "forecast" */
export type Forecast_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  estimatedAway?: Maybe<Scalars['Int']>;
  estimatedHome?: Maybe<Scalars['Int']>;
  matchId?: Maybe<Scalars['uuid']>;
  profileId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Forecast_Status_Enum>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "forecast_status" */
export type Forecast_Status = {
  __typename?: 'forecast_status';
  comment: Scalars['String'];
  /** An object relationship */
  forecastStatusPoints?: Maybe<Forecast_Status_Points>;
  /** An array relationship */
  forecast_status_points: Array<Forecast_Status_Points>;
  /** An aggregated array relationship */
  forecast_status_points_aggregate: Forecast_Status_Points_Aggregate;
  /** An array relationship */
  forecasts: Array<Forecast>;
  /** An aggregated array relationship */
  forecasts_aggregate: Forecast_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "forecast_status" */
export type Forecast_StatusForecast_Status_PointsArgs = {
  distinct_on?: Maybe<Array<Forecast_Status_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Status_Points_Order_By>>;
  where?: Maybe<Forecast_Status_Points_Bool_Exp>;
};

/** columns and relationships of "forecast_status" */
export type Forecast_StatusForecast_Status_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Status_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Status_Points_Order_By>>;
  where?: Maybe<Forecast_Status_Points_Bool_Exp>;
};

/** columns and relationships of "forecast_status" */
export type Forecast_StatusForecastsArgs = {
  distinct_on?: Maybe<Array<Forecast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Order_By>>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** columns and relationships of "forecast_status" */
export type Forecast_StatusForecasts_AggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Order_By>>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** aggregated selection of "forecast_status" */
export type Forecast_Status_Aggregate = {
  __typename?: 'forecast_status_aggregate';
  aggregate?: Maybe<Forecast_Status_Aggregate_Fields>;
  nodes: Array<Forecast_Status>;
};

/** aggregate fields of "forecast_status" */
export type Forecast_Status_Aggregate_Fields = {
  __typename?: 'forecast_status_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Forecast_Status_Max_Fields>;
  min?: Maybe<Forecast_Status_Min_Fields>;
};

/** aggregate fields of "forecast_status" */
export type Forecast_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Forecast_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "forecast_status" */
export type Forecast_Status_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Forecast_Status_Max_Order_By>;
  min?: Maybe<Forecast_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "forecast_status" */
export type Forecast_Status_Arr_Rel_Insert_Input = {
  data: Array<Forecast_Status_Insert_Input>;
  on_conflict?: Maybe<Forecast_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "forecast_status". All fields are combined with a logical 'AND'. */
export type Forecast_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Forecast_Status_Bool_Exp>>>;
  _not?: Maybe<Forecast_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Forecast_Status_Bool_Exp>>>;
  comment?: Maybe<String_Comparison_Exp>;
  forecastStatusPoints?: Maybe<Forecast_Status_Points_Bool_Exp>;
  forecast_status_points?: Maybe<Forecast_Status_Points_Bool_Exp>;
  forecasts?: Maybe<Forecast_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "forecast_status" */
export enum Forecast_Status_Constraint {
  /** unique or primary key constraint */
  ForecastsStatusPkey = 'forecasts_status_pkey',
}

export enum Forecast_Status_Enum {
  Miss = 'miss',
  /** same victory team */
  Partial = 'partial',
  /** exact score */
  Perfect = 'perfect',
}

/** expression to compare columns of type forecast_status_enum. All fields are combined with logical 'AND'. */
export type Forecast_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Forecast_Status_Enum>;
  _in?: Maybe<Array<Forecast_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Forecast_Status_Enum>;
  _nin?: Maybe<Array<Forecast_Status_Enum>>;
};

/** input type for inserting data into table "forecast_status" */
export type Forecast_Status_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  forecastStatusPoints?: Maybe<Forecast_Status_Points_Obj_Rel_Insert_Input>;
  forecast_status_points?: Maybe<Forecast_Status_Points_Arr_Rel_Insert_Input>;
  forecasts?: Maybe<Forecast_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Forecast_Status_Max_Fields = {
  __typename?: 'forecast_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "forecast_status" */
export type Forecast_Status_Max_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Forecast_Status_Min_Fields = {
  __typename?: 'forecast_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "forecast_status" */
export type Forecast_Status_Min_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "forecast_status" */
export type Forecast_Status_Mutation_Response = {
  __typename?: 'forecast_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Forecast_Status>;
};

/** input type for inserting object relation for remote table "forecast_status" */
export type Forecast_Status_Obj_Rel_Insert_Input = {
  data: Forecast_Status_Insert_Input;
  on_conflict?: Maybe<Forecast_Status_On_Conflict>;
};

/** on conflict condition type for table "forecast_status" */
export type Forecast_Status_On_Conflict = {
  constraint: Forecast_Status_Constraint;
  update_columns: Array<Forecast_Status_Update_Column>;
  where?: Maybe<Forecast_Status_Bool_Exp>;
};

/** ordering options when selecting data from "forecast_status" */
export type Forecast_Status_Order_By = {
  comment?: Maybe<Order_By>;
  forecastStatusPoints?: Maybe<Forecast_Status_Points_Order_By>;
  forecast_status_points_aggregate?: Maybe<Forecast_Status_Points_Aggregate_Order_By>;
  forecasts_aggregate?: Maybe<Forecast_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "forecast_status" */
export type Forecast_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** columns and relationships of "forecast_status_points" */
export type Forecast_Status_Points = {
  __typename?: 'forecast_status_points';
  /** An object relationship */
  forecastStatusByForecastStatus: Forecast_Status;
  forecast_status: Forecast_Status_Enum;
  points: Scalars['Int'];
};

/** aggregated selection of "forecast_status_points" */
export type Forecast_Status_Points_Aggregate = {
  __typename?: 'forecast_status_points_aggregate';
  aggregate?: Maybe<Forecast_Status_Points_Aggregate_Fields>;
  nodes: Array<Forecast_Status_Points>;
};

/** aggregate fields of "forecast_status_points" */
export type Forecast_Status_Points_Aggregate_Fields = {
  __typename?: 'forecast_status_points_aggregate_fields';
  avg?: Maybe<Forecast_Status_Points_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Forecast_Status_Points_Max_Fields>;
  min?: Maybe<Forecast_Status_Points_Min_Fields>;
  stddev?: Maybe<Forecast_Status_Points_Stddev_Fields>;
  stddev_pop?: Maybe<Forecast_Status_Points_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Forecast_Status_Points_Stddev_Samp_Fields>;
  sum?: Maybe<Forecast_Status_Points_Sum_Fields>;
  var_pop?: Maybe<Forecast_Status_Points_Var_Pop_Fields>;
  var_samp?: Maybe<Forecast_Status_Points_Var_Samp_Fields>;
  variance?: Maybe<Forecast_Status_Points_Variance_Fields>;
};

/** aggregate fields of "forecast_status_points" */
export type Forecast_Status_Points_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Forecast_Status_Points_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "forecast_status_points" */
export type Forecast_Status_Points_Aggregate_Order_By = {
  avg?: Maybe<Forecast_Status_Points_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Forecast_Status_Points_Max_Order_By>;
  min?: Maybe<Forecast_Status_Points_Min_Order_By>;
  stddev?: Maybe<Forecast_Status_Points_Stddev_Order_By>;
  stddev_pop?: Maybe<Forecast_Status_Points_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Forecast_Status_Points_Stddev_Samp_Order_By>;
  sum?: Maybe<Forecast_Status_Points_Sum_Order_By>;
  var_pop?: Maybe<Forecast_Status_Points_Var_Pop_Order_By>;
  var_samp?: Maybe<Forecast_Status_Points_Var_Samp_Order_By>;
  variance?: Maybe<Forecast_Status_Points_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "forecast_status_points" */
export type Forecast_Status_Points_Arr_Rel_Insert_Input = {
  data: Array<Forecast_Status_Points_Insert_Input>;
  on_conflict?: Maybe<Forecast_Status_Points_On_Conflict>;
};

/** aggregate avg on columns */
export type Forecast_Status_Points_Avg_Fields = {
  __typename?: 'forecast_status_points_avg_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "forecast_status_points" */
export type Forecast_Status_Points_Avg_Order_By = {
  points?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "forecast_status_points". All fields are combined with a logical 'AND'. */
export type Forecast_Status_Points_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Forecast_Status_Points_Bool_Exp>>>;
  _not?: Maybe<Forecast_Status_Points_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Forecast_Status_Points_Bool_Exp>>>;
  forecastStatusByForecastStatus?: Maybe<Forecast_Status_Bool_Exp>;
  forecast_status?: Maybe<Forecast_Status_Enum_Comparison_Exp>;
  points?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "forecast_status_points" */
export enum Forecast_Status_Points_Constraint {
  /** unique or primary key constraint */
  ForecastStatusPointsPkey = 'forecast_status_points_pkey',
}

/** input type for incrementing integer column in table "forecast_status_points" */
export type Forecast_Status_Points_Inc_Input = {
  points?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "forecast_status_points" */
export type Forecast_Status_Points_Insert_Input = {
  forecastStatusByForecastStatus?: Maybe<Forecast_Status_Obj_Rel_Insert_Input>;
  forecast_status?: Maybe<Forecast_Status_Enum>;
  points?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Forecast_Status_Points_Max_Fields = {
  __typename?: 'forecast_status_points_max_fields';
  points?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "forecast_status_points" */
export type Forecast_Status_Points_Max_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Forecast_Status_Points_Min_Fields = {
  __typename?: 'forecast_status_points_min_fields';
  points?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "forecast_status_points" */
export type Forecast_Status_Points_Min_Order_By = {
  points?: Maybe<Order_By>;
};

/** response of any mutation on the table "forecast_status_points" */
export type Forecast_Status_Points_Mutation_Response = {
  __typename?: 'forecast_status_points_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Forecast_Status_Points>;
};

/** input type for inserting object relation for remote table "forecast_status_points" */
export type Forecast_Status_Points_Obj_Rel_Insert_Input = {
  data: Forecast_Status_Points_Insert_Input;
  on_conflict?: Maybe<Forecast_Status_Points_On_Conflict>;
};

/** on conflict condition type for table "forecast_status_points" */
export type Forecast_Status_Points_On_Conflict = {
  constraint: Forecast_Status_Points_Constraint;
  update_columns: Array<Forecast_Status_Points_Update_Column>;
  where?: Maybe<Forecast_Status_Points_Bool_Exp>;
};

/** ordering options when selecting data from "forecast_status_points" */
export type Forecast_Status_Points_Order_By = {
  forecastStatusByForecastStatus?: Maybe<Forecast_Status_Order_By>;
  forecast_status?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
};

/** primary key columns input for table: "forecast_status_points" */
export type Forecast_Status_Points_Pk_Columns_Input = {
  forecast_status: Forecast_Status_Enum;
};

/** select columns of table "forecast_status_points" */
export enum Forecast_Status_Points_Select_Column {
  /** column name */
  ForecastStatus = 'forecast_status',
  /** column name */
  Points = 'points',
}

/** input type for updating data in table "forecast_status_points" */
export type Forecast_Status_Points_Set_Input = {
  forecast_status?: Maybe<Forecast_Status_Enum>;
  points?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Forecast_Status_Points_Stddev_Fields = {
  __typename?: 'forecast_status_points_stddev_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "forecast_status_points" */
export type Forecast_Status_Points_Stddev_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Forecast_Status_Points_Stddev_Pop_Fields = {
  __typename?: 'forecast_status_points_stddev_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "forecast_status_points" */
export type Forecast_Status_Points_Stddev_Pop_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Forecast_Status_Points_Stddev_Samp_Fields = {
  __typename?: 'forecast_status_points_stddev_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "forecast_status_points" */
export type Forecast_Status_Points_Stddev_Samp_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Forecast_Status_Points_Sum_Fields = {
  __typename?: 'forecast_status_points_sum_fields';
  points?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "forecast_status_points" */
export type Forecast_Status_Points_Sum_Order_By = {
  points?: Maybe<Order_By>;
};

/** update columns of table "forecast_status_points" */
export enum Forecast_Status_Points_Update_Column {
  /** column name */
  ForecastStatus = 'forecast_status',
  /** column name */
  Points = 'points',
}

/** aggregate var_pop on columns */
export type Forecast_Status_Points_Var_Pop_Fields = {
  __typename?: 'forecast_status_points_var_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "forecast_status_points" */
export type Forecast_Status_Points_Var_Pop_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Forecast_Status_Points_Var_Samp_Fields = {
  __typename?: 'forecast_status_points_var_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "forecast_status_points" */
export type Forecast_Status_Points_Var_Samp_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Forecast_Status_Points_Variance_Fields = {
  __typename?: 'forecast_status_points_variance_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "forecast_status_points" */
export type Forecast_Status_Points_Variance_Order_By = {
  points?: Maybe<Order_By>;
};

/** select columns of table "forecast_status" */
export enum Forecast_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "forecast_status" */
export type Forecast_Status_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "forecast_status" */
export enum Forecast_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** aggregate stddev on columns */
export type Forecast_Stddev_Fields = {
  __typename?: 'forecast_stddev_fields';
  estimatedAway?: Maybe<Scalars['Float']>;
  estimatedHome?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "forecast" */
export type Forecast_Stddev_Order_By = {
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Forecast_Stddev_Pop_Fields = {
  __typename?: 'forecast_stddev_pop_fields';
  estimatedAway?: Maybe<Scalars['Float']>;
  estimatedHome?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "forecast" */
export type Forecast_Stddev_Pop_Order_By = {
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Forecast_Stddev_Samp_Fields = {
  __typename?: 'forecast_stddev_samp_fields';
  estimatedAway?: Maybe<Scalars['Float']>;
  estimatedHome?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "forecast" */
export type Forecast_Stddev_Samp_Order_By = {
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Forecast_Sum_Fields = {
  __typename?: 'forecast_sum_fields';
  estimatedAway?: Maybe<Scalars['Int']>;
  estimatedHome?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "forecast" */
export type Forecast_Sum_Order_By = {
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
};

/** update columns of table "forecast" */
export enum Forecast_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EstimatedAway = 'estimatedAway',
  /** column name */
  EstimatedHome = 'estimatedHome',
  /** column name */
  MatchId = 'matchId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** aggregate var_pop on columns */
export type Forecast_Var_Pop_Fields = {
  __typename?: 'forecast_var_pop_fields';
  estimatedAway?: Maybe<Scalars['Float']>;
  estimatedHome?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "forecast" */
export type Forecast_Var_Pop_Order_By = {
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Forecast_Var_Samp_Fields = {
  __typename?: 'forecast_var_samp_fields';
  estimatedAway?: Maybe<Scalars['Float']>;
  estimatedHome?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "forecast" */
export type Forecast_Var_Samp_Order_By = {
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Forecast_Variance_Fields = {
  __typename?: 'forecast_variance_fields';
  estimatedAway?: Maybe<Scalars['Float']>;
  estimatedHome?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "forecast" */
export type Forecast_Variance_Order_By = {
  estimatedAway?: Maybe<Order_By>;
  estimatedHome?: Maybe<Order_By>;
};

/** columns and relationships of "group" */
export type Group = {
  __typename?: 'group';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  cup: Cup;
  cup_id: Scalars['uuid'];
  /** An array relationship */
  groupMembersPointsAndRank: Array<Group_Profil_Points_And_Rank>;
  /** An aggregated array relationship */
  groupMembersPointsAndRank_aggregate: Group_Profil_Points_And_Rank_Aggregate;
  /** An array relationship */
  group_invites: Array<Group_Invite>;
  /** An aggregated array relationship */
  group_invites_aggregate: Group_Invite_Aggregate;
  /** An array relationship */
  group_members: Array<Group_Member>;
  /** An aggregated array relationship */
  group_members_aggregate: Group_Member_Aggregate;
  /** An object relationship */
  group_points_and_rank?: Maybe<Group_Points_And_Rank>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  photo_url?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "group" */
export type GroupGroupMembersPointsAndRankArgs = {
  distinct_on?: Maybe<Array<Group_Profil_Points_And_Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Profil_Points_And_Rank_Order_By>>;
  where?: Maybe<Group_Profil_Points_And_Rank_Bool_Exp>;
};

/** columns and relationships of "group" */
export type GroupGroupMembersPointsAndRank_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Profil_Points_And_Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Profil_Points_And_Rank_Order_By>>;
  where?: Maybe<Group_Profil_Points_And_Rank_Bool_Exp>;
};

/** columns and relationships of "group" */
export type GroupGroup_InvitesArgs = {
  distinct_on?: Maybe<Array<Group_Invite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Invite_Order_By>>;
  where?: Maybe<Group_Invite_Bool_Exp>;
};

/** columns and relationships of "group" */
export type GroupGroup_Invites_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Invite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Invite_Order_By>>;
  where?: Maybe<Group_Invite_Bool_Exp>;
};

/** columns and relationships of "group" */
export type GroupGroup_MembersArgs = {
  distinct_on?: Maybe<Array<Group_Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Member_Order_By>>;
  where?: Maybe<Group_Member_Bool_Exp>;
};

/** columns and relationships of "group" */
export type GroupGroup_Members_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Member_Order_By>>;
  where?: Maybe<Group_Member_Bool_Exp>;
};

/** aggregated selection of "group" */
export type Group_Aggregate = {
  __typename?: 'group_aggregate';
  aggregate?: Maybe<Group_Aggregate_Fields>;
  nodes: Array<Group>;
};

/** aggregate fields of "group" */
export type Group_Aggregate_Fields = {
  __typename?: 'group_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Group_Max_Fields>;
  min?: Maybe<Group_Min_Fields>;
};

/** aggregate fields of "group" */
export type Group_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Group_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group" */
export type Group_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Group_Max_Order_By>;
  min?: Maybe<Group_Min_Order_By>;
};

/** input type for inserting array relation for remote table "group" */
export type Group_Arr_Rel_Insert_Input = {
  data: Array<Group_Insert_Input>;
  on_conflict?: Maybe<Group_On_Conflict>;
};

/** Boolean expression to filter rows from the table "group". All fields are combined with a logical 'AND'. */
export type Group_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Group_Bool_Exp>>>;
  _not?: Maybe<Group_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Group_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cup?: Maybe<Cup_Bool_Exp>;
  cup_id?: Maybe<Uuid_Comparison_Exp>;
  groupMembersPointsAndRank?: Maybe<Group_Profil_Points_And_Rank_Bool_Exp>;
  group_invites?: Maybe<Group_Invite_Bool_Exp>;
  group_members?: Maybe<Group_Member_Bool_Exp>;
  group_points_and_rank?: Maybe<Group_Points_And_Rank_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  photo_url?: Maybe<String_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "group" */
export enum Group_Constraint {
  /** unique or primary key constraint */
  GroupPkey = 'group_pkey',
  /** unique or primary key constraint */
  GroupSlugCupIdKey = 'group_slug_cup_id_key',
}

/** input type for inserting data into table "group" */
export type Group_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  cup?: Maybe<Cup_Obj_Rel_Insert_Input>;
  cup_id?: Maybe<Scalars['uuid']>;
  group_invites?: Maybe<Group_Invite_Arr_Rel_Insert_Input>;
  group_members?: Maybe<Group_Member_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "group_invite" */
export type Group_Invite = {
  __typename?: 'group_invite';
  created_at: Scalars['timestamptz'];
  expiration_date: Scalars['timestamptz'];
  /** An object relationship */
  group?: Maybe<Group>;
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  is_disabled: Scalars['Boolean'];
  nmb_used: Scalars['numeric'];
  public_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "group_invite" */
export type Group_Invite_Aggregate = {
  __typename?: 'group_invite_aggregate';
  aggregate?: Maybe<Group_Invite_Aggregate_Fields>;
  nodes: Array<Group_Invite>;
};

/** aggregate fields of "group_invite" */
export type Group_Invite_Aggregate_Fields = {
  __typename?: 'group_invite_aggregate_fields';
  avg?: Maybe<Group_Invite_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Group_Invite_Max_Fields>;
  min?: Maybe<Group_Invite_Min_Fields>;
  stddev?: Maybe<Group_Invite_Stddev_Fields>;
  stddev_pop?: Maybe<Group_Invite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Group_Invite_Stddev_Samp_Fields>;
  sum?: Maybe<Group_Invite_Sum_Fields>;
  var_pop?: Maybe<Group_Invite_Var_Pop_Fields>;
  var_samp?: Maybe<Group_Invite_Var_Samp_Fields>;
  variance?: Maybe<Group_Invite_Variance_Fields>;
};

/** aggregate fields of "group_invite" */
export type Group_Invite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Group_Invite_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group_invite" */
export type Group_Invite_Aggregate_Order_By = {
  avg?: Maybe<Group_Invite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Group_Invite_Max_Order_By>;
  min?: Maybe<Group_Invite_Min_Order_By>;
  stddev?: Maybe<Group_Invite_Stddev_Order_By>;
  stddev_pop?: Maybe<Group_Invite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Group_Invite_Stddev_Samp_Order_By>;
  sum?: Maybe<Group_Invite_Sum_Order_By>;
  var_pop?: Maybe<Group_Invite_Var_Pop_Order_By>;
  var_samp?: Maybe<Group_Invite_Var_Samp_Order_By>;
  variance?: Maybe<Group_Invite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "group_invite" */
export type Group_Invite_Arr_Rel_Insert_Input = {
  data: Array<Group_Invite_Insert_Input>;
  on_conflict?: Maybe<Group_Invite_On_Conflict>;
};

/** aggregate avg on columns */
export type Group_Invite_Avg_Fields = {
  __typename?: 'group_invite_avg_fields';
  nmb_used?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "group_invite" */
export type Group_Invite_Avg_Order_By = {
  nmb_used?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "group_invite". All fields are combined with a logical 'AND'. */
export type Group_Invite_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Group_Invite_Bool_Exp>>>;
  _not?: Maybe<Group_Invite_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Group_Invite_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expiration_date?: Maybe<Timestamptz_Comparison_Exp>;
  group?: Maybe<Group_Bool_Exp>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_disabled?: Maybe<Boolean_Comparison_Exp>;
  nmb_used?: Maybe<Numeric_Comparison_Exp>;
  public_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_invite" */
export enum Group_Invite_Constraint {
  /** unique or primary key constraint */
  GroupInvitePkey = 'group_invite_pkey',
}

/** input type for incrementing integer column in table "group_invite" */
export type Group_Invite_Inc_Input = {
  nmb_used?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "group_invite" */
export type Group_Invite_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Group_Obj_Rel_Insert_Input>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  nmb_used?: Maybe<Scalars['numeric']>;
  public_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Group_Invite_Max_Fields = {
  __typename?: 'group_invite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  nmb_used?: Maybe<Scalars['numeric']>;
  public_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "group_invite" */
export type Group_Invite_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  expiration_date?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  nmb_used?: Maybe<Order_By>;
  public_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Group_Invite_Min_Fields = {
  __typename?: 'group_invite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  nmb_used?: Maybe<Scalars['numeric']>;
  public_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "group_invite" */
export type Group_Invite_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  expiration_date?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  nmb_used?: Maybe<Order_By>;
  public_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "group_invite" */
export type Group_Invite_Mutation_Response = {
  __typename?: 'group_invite_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Group_Invite>;
};

/** input type for inserting object relation for remote table "group_invite" */
export type Group_Invite_Obj_Rel_Insert_Input = {
  data: Group_Invite_Insert_Input;
  on_conflict?: Maybe<Group_Invite_On_Conflict>;
};

/** on conflict condition type for table "group_invite" */
export type Group_Invite_On_Conflict = {
  constraint: Group_Invite_Constraint;
  update_columns: Array<Group_Invite_Update_Column>;
  where?: Maybe<Group_Invite_Bool_Exp>;
};

/** ordering options when selecting data from "group_invite" */
export type Group_Invite_Order_By = {
  created_at?: Maybe<Order_By>;
  expiration_date?: Maybe<Order_By>;
  group?: Maybe<Group_Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_disabled?: Maybe<Order_By>;
  nmb_used?: Maybe<Order_By>;
  public_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "group_invite" */
export type Group_Invite_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "group_invite" */
export enum Group_Invite_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDisabled = 'is_disabled',
  /** column name */
  NmbUsed = 'nmb_used',
  /** column name */
  PublicId = 'public_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "group_invite" */
export type Group_Invite_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  nmb_used?: Maybe<Scalars['numeric']>;
  public_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Group_Invite_Stddev_Fields = {
  __typename?: 'group_invite_stddev_fields';
  nmb_used?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "group_invite" */
export type Group_Invite_Stddev_Order_By = {
  nmb_used?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Group_Invite_Stddev_Pop_Fields = {
  __typename?: 'group_invite_stddev_pop_fields';
  nmb_used?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "group_invite" */
export type Group_Invite_Stddev_Pop_Order_By = {
  nmb_used?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Group_Invite_Stddev_Samp_Fields = {
  __typename?: 'group_invite_stddev_samp_fields';
  nmb_used?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "group_invite" */
export type Group_Invite_Stddev_Samp_Order_By = {
  nmb_used?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Group_Invite_Sum_Fields = {
  __typename?: 'group_invite_sum_fields';
  nmb_used?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "group_invite" */
export type Group_Invite_Sum_Order_By = {
  nmb_used?: Maybe<Order_By>;
};

/** update columns of table "group_invite" */
export enum Group_Invite_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDisabled = 'is_disabled',
  /** column name */
  NmbUsed = 'nmb_used',
  /** column name */
  PublicId = 'public_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate var_pop on columns */
export type Group_Invite_Var_Pop_Fields = {
  __typename?: 'group_invite_var_pop_fields';
  nmb_used?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "group_invite" */
export type Group_Invite_Var_Pop_Order_By = {
  nmb_used?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Group_Invite_Var_Samp_Fields = {
  __typename?: 'group_invite_var_samp_fields';
  nmb_used?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "group_invite" */
export type Group_Invite_Var_Samp_Order_By = {
  nmb_used?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Group_Invite_Variance_Fields = {
  __typename?: 'group_invite_variance_fields';
  nmb_used?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "group_invite" */
export type Group_Invite_Variance_Order_By = {
  nmb_used?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Group_Max_Fields = {
  __typename?: 'group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cup_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "group" */
export type Group_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  cup_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "group_member" */
export type Group_Member = {
  __typename?: 'group_member';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  group: Group;
  group_id: Scalars['uuid'];
  id: Scalars['uuid'];
  is_admin: Scalars['Boolean'];
  joined_at: Scalars['timestamptz'];
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "group_member" */
export type Group_Member_Aggregate = {
  __typename?: 'group_member_aggregate';
  aggregate?: Maybe<Group_Member_Aggregate_Fields>;
  nodes: Array<Group_Member>;
};

/** aggregate fields of "group_member" */
export type Group_Member_Aggregate_Fields = {
  __typename?: 'group_member_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Group_Member_Max_Fields>;
  min?: Maybe<Group_Member_Min_Fields>;
};

/** aggregate fields of "group_member" */
export type Group_Member_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Group_Member_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group_member" */
export type Group_Member_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Group_Member_Max_Order_By>;
  min?: Maybe<Group_Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "group_member" */
export type Group_Member_Arr_Rel_Insert_Input = {
  data: Array<Group_Member_Insert_Input>;
  on_conflict?: Maybe<Group_Member_On_Conflict>;
};

/** Boolean expression to filter rows from the table "group_member". All fields are combined with a logical 'AND'. */
export type Group_Member_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Group_Member_Bool_Exp>>>;
  _not?: Maybe<Group_Member_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Group_Member_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  group?: Maybe<Group_Bool_Exp>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_admin?: Maybe<Boolean_Comparison_Exp>;
  joined_at?: Maybe<Timestamptz_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profile_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_member" */
export enum Group_Member_Constraint {
  /** unique or primary key constraint */
  GroupMemberPkey = 'group_member_pkey',
  /** unique or primary key constraint */
  GroupMemberProfileIdGroupIdKey = 'group_member_profile_id_group_id_key',
}

/** input type for inserting data into table "group_member" */
export type Group_Member_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  group?: Maybe<Group_Obj_Rel_Insert_Input>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_admin?: Maybe<Scalars['Boolean']>;
  joined_at?: Maybe<Scalars['timestamptz']>;
  profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Group_Member_Max_Fields = {
  __typename?: 'group_member_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  joined_at?: Maybe<Scalars['timestamptz']>;
  profile_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "group_member" */
export type Group_Member_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  joined_at?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Group_Member_Min_Fields = {
  __typename?: 'group_member_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  joined_at?: Maybe<Scalars['timestamptz']>;
  profile_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "group_member" */
export type Group_Member_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  joined_at?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "group_member" */
export type Group_Member_Mutation_Response = {
  __typename?: 'group_member_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Group_Member>;
};

/** input type for inserting object relation for remote table "group_member" */
export type Group_Member_Obj_Rel_Insert_Input = {
  data: Group_Member_Insert_Input;
  on_conflict?: Maybe<Group_Member_On_Conflict>;
};

/** on conflict condition type for table "group_member" */
export type Group_Member_On_Conflict = {
  constraint: Group_Member_Constraint;
  update_columns: Array<Group_Member_Update_Column>;
  where?: Maybe<Group_Member_Bool_Exp>;
};

/** ordering options when selecting data from "group_member" */
export type Group_Member_Order_By = {
  created_at?: Maybe<Order_By>;
  group?: Maybe<Group_Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_admin?: Maybe<Order_By>;
  joined_at?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "group_member" */
export type Group_Member_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "group_member" */
export enum Group_Member_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  JoinedAt = 'joined_at',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "group_member" */
export type Group_Member_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_admin?: Maybe<Scalars['Boolean']>;
  joined_at?: Maybe<Scalars['timestamptz']>;
  profile_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "group_member" */
export enum Group_Member_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  JoinedAt = 'joined_at',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate min on columns */
export type Group_Min_Fields = {
  __typename?: 'group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cup_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "group" */
export type Group_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  cup_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "group" */
export type Group_Mutation_Response = {
  __typename?: 'group_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Group>;
};

/** input type for inserting object relation for remote table "group" */
export type Group_Obj_Rel_Insert_Input = {
  data: Group_Insert_Input;
  on_conflict?: Maybe<Group_On_Conflict>;
};

/** on conflict condition type for table "group" */
export type Group_On_Conflict = {
  constraint: Group_Constraint;
  update_columns: Array<Group_Update_Column>;
  where?: Maybe<Group_Bool_Exp>;
};

/** ordering options when selecting data from "group" */
export type Group_Order_By = {
  created_at?: Maybe<Order_By>;
  cup?: Maybe<Cup_Order_By>;
  cup_id?: Maybe<Order_By>;
  groupMembersPointsAndRank_aggregate?: Maybe<Group_Profil_Points_And_Rank_Aggregate_Order_By>;
  group_invites_aggregate?: Maybe<Group_Invite_Aggregate_Order_By>;
  group_members_aggregate?: Maybe<Group_Member_Aggregate_Order_By>;
  group_points_and_rank?: Maybe<Group_Points_And_Rank_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "group" */
export type Group_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "group_points_and_rank" */
export type Group_Points_And_Rank = {
  __typename?: 'group_points_and_rank';
  /** An object relationship */
  group?: Maybe<Group>;
  group_id?: Maybe<Scalars['uuid']>;
  miss?: Maybe<Scalars['numeric']>;
  partial?: Maybe<Scalars['numeric']>;
  perfect?: Maybe<Scalars['numeric']>;
  rank?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "group_points_and_rank" */
export type Group_Points_And_Rank_Aggregate = {
  __typename?: 'group_points_and_rank_aggregate';
  aggregate?: Maybe<Group_Points_And_Rank_Aggregate_Fields>;
  nodes: Array<Group_Points_And_Rank>;
};

/** aggregate fields of "group_points_and_rank" */
export type Group_Points_And_Rank_Aggregate_Fields = {
  __typename?: 'group_points_and_rank_aggregate_fields';
  avg?: Maybe<Group_Points_And_Rank_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Group_Points_And_Rank_Max_Fields>;
  min?: Maybe<Group_Points_And_Rank_Min_Fields>;
  stddev?: Maybe<Group_Points_And_Rank_Stddev_Fields>;
  stddev_pop?: Maybe<Group_Points_And_Rank_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Group_Points_And_Rank_Stddev_Samp_Fields>;
  sum?: Maybe<Group_Points_And_Rank_Sum_Fields>;
  var_pop?: Maybe<Group_Points_And_Rank_Var_Pop_Fields>;
  var_samp?: Maybe<Group_Points_And_Rank_Var_Samp_Fields>;
  variance?: Maybe<Group_Points_And_Rank_Variance_Fields>;
};

/** aggregate fields of "group_points_and_rank" */
export type Group_Points_And_Rank_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Group_Points_And_Rank_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group_points_and_rank" */
export type Group_Points_And_Rank_Aggregate_Order_By = {
  avg?: Maybe<Group_Points_And_Rank_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Group_Points_And_Rank_Max_Order_By>;
  min?: Maybe<Group_Points_And_Rank_Min_Order_By>;
  stddev?: Maybe<Group_Points_And_Rank_Stddev_Order_By>;
  stddev_pop?: Maybe<Group_Points_And_Rank_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Group_Points_And_Rank_Stddev_Samp_Order_By>;
  sum?: Maybe<Group_Points_And_Rank_Sum_Order_By>;
  var_pop?: Maybe<Group_Points_And_Rank_Var_Pop_Order_By>;
  var_samp?: Maybe<Group_Points_And_Rank_Var_Samp_Order_By>;
  variance?: Maybe<Group_Points_And_Rank_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Group_Points_And_Rank_Avg_Fields = {
  __typename?: 'group_points_and_rank_avg_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "group_points_and_rank" */
export type Group_Points_And_Rank_Avg_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "group_points_and_rank". All fields are combined with a logical 'AND'. */
export type Group_Points_And_Rank_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Group_Points_And_Rank_Bool_Exp>>>;
  _not?: Maybe<Group_Points_And_Rank_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Group_Points_And_Rank_Bool_Exp>>>;
  group?: Maybe<Group_Bool_Exp>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  miss?: Maybe<Numeric_Comparison_Exp>;
  partial?: Maybe<Numeric_Comparison_Exp>;
  perfect?: Maybe<Numeric_Comparison_Exp>;
  rank?: Maybe<Bigint_Comparison_Exp>;
  total_points?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Group_Points_And_Rank_Max_Fields = {
  __typename?: 'group_points_and_rank_max_fields';
  group_id?: Maybe<Scalars['uuid']>;
  miss?: Maybe<Scalars['numeric']>;
  partial?: Maybe<Scalars['numeric']>;
  perfect?: Maybe<Scalars['numeric']>;
  rank?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "group_points_and_rank" */
export type Group_Points_And_Rank_Max_Order_By = {
  group_id?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Group_Points_And_Rank_Min_Fields = {
  __typename?: 'group_points_and_rank_min_fields';
  group_id?: Maybe<Scalars['uuid']>;
  miss?: Maybe<Scalars['numeric']>;
  partial?: Maybe<Scalars['numeric']>;
  perfect?: Maybe<Scalars['numeric']>;
  rank?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "group_points_and_rank" */
export type Group_Points_And_Rank_Min_Order_By = {
  group_id?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** ordering options when selecting data from "group_points_and_rank" */
export type Group_Points_And_Rank_Order_By = {
  group?: Maybe<Group_Order_By>;
  group_id?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** select columns of table "group_points_and_rank" */
export enum Group_Points_And_Rank_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Miss = 'miss',
  /** column name */
  Partial = 'partial',
  /** column name */
  Perfect = 'perfect',
  /** column name */
  Rank = 'rank',
  /** column name */
  TotalPoints = 'total_points',
}

/** aggregate stddev on columns */
export type Group_Points_And_Rank_Stddev_Fields = {
  __typename?: 'group_points_and_rank_stddev_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "group_points_and_rank" */
export type Group_Points_And_Rank_Stddev_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Group_Points_And_Rank_Stddev_Pop_Fields = {
  __typename?: 'group_points_and_rank_stddev_pop_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "group_points_and_rank" */
export type Group_Points_And_Rank_Stddev_Pop_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Group_Points_And_Rank_Stddev_Samp_Fields = {
  __typename?: 'group_points_and_rank_stddev_samp_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "group_points_and_rank" */
export type Group_Points_And_Rank_Stddev_Samp_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Group_Points_And_Rank_Sum_Fields = {
  __typename?: 'group_points_and_rank_sum_fields';
  miss?: Maybe<Scalars['numeric']>;
  partial?: Maybe<Scalars['numeric']>;
  perfect?: Maybe<Scalars['numeric']>;
  rank?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "group_points_and_rank" */
export type Group_Points_And_Rank_Sum_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Group_Points_And_Rank_Var_Pop_Fields = {
  __typename?: 'group_points_and_rank_var_pop_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "group_points_and_rank" */
export type Group_Points_And_Rank_Var_Pop_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Group_Points_And_Rank_Var_Samp_Fields = {
  __typename?: 'group_points_and_rank_var_samp_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "group_points_and_rank" */
export type Group_Points_And_Rank_Var_Samp_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Group_Points_And_Rank_Variance_Fields = {
  __typename?: 'group_points_and_rank_variance_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "group_points_and_rank" */
export type Group_Points_And_Rank_Variance_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** columns and relationships of "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank = {
  __typename?: 'group_profil_points_and_rank';
  /** An object relationship */
  cup?: Maybe<Cup>;
  cup_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  group?: Maybe<Group>;
  group_id?: Maybe<Scalars['uuid']>;
  miss?: Maybe<Scalars['bigint']>;
  partial?: Maybe<Scalars['bigint']>;
  perfect?: Maybe<Scalars['bigint']>;
  point_user?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  profile_id?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Aggregate = {
  __typename?: 'group_profil_points_and_rank_aggregate';
  aggregate?: Maybe<Group_Profil_Points_And_Rank_Aggregate_Fields>;
  nodes: Array<Group_Profil_Points_And_Rank>;
};

/** aggregate fields of "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Aggregate_Fields = {
  __typename?: 'group_profil_points_and_rank_aggregate_fields';
  avg?: Maybe<Group_Profil_Points_And_Rank_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Group_Profil_Points_And_Rank_Max_Fields>;
  min?: Maybe<Group_Profil_Points_And_Rank_Min_Fields>;
  stddev?: Maybe<Group_Profil_Points_And_Rank_Stddev_Fields>;
  stddev_pop?: Maybe<Group_Profil_Points_And_Rank_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Group_Profil_Points_And_Rank_Stddev_Samp_Fields>;
  sum?: Maybe<Group_Profil_Points_And_Rank_Sum_Fields>;
  var_pop?: Maybe<Group_Profil_Points_And_Rank_Var_Pop_Fields>;
  var_samp?: Maybe<Group_Profil_Points_And_Rank_Var_Samp_Fields>;
  variance?: Maybe<Group_Profil_Points_And_Rank_Variance_Fields>;
};

/** aggregate fields of "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Group_Profil_Points_And_Rank_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Aggregate_Order_By = {
  avg?: Maybe<Group_Profil_Points_And_Rank_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Group_Profil_Points_And_Rank_Max_Order_By>;
  min?: Maybe<Group_Profil_Points_And_Rank_Min_Order_By>;
  stddev?: Maybe<Group_Profil_Points_And_Rank_Stddev_Order_By>;
  stddev_pop?: Maybe<Group_Profil_Points_And_Rank_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Group_Profil_Points_And_Rank_Stddev_Samp_Order_By>;
  sum?: Maybe<Group_Profil_Points_And_Rank_Sum_Order_By>;
  var_pop?: Maybe<Group_Profil_Points_And_Rank_Var_Pop_Order_By>;
  var_samp?: Maybe<Group_Profil_Points_And_Rank_Var_Samp_Order_By>;
  variance?: Maybe<Group_Profil_Points_And_Rank_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Group_Profil_Points_And_Rank_Avg_Fields = {
  __typename?: 'group_profil_points_and_rank_avg_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  point_user?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Avg_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "group_profil_points_and_rank". All fields are combined with a logical 'AND'. */
export type Group_Profil_Points_And_Rank_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Group_Profil_Points_And_Rank_Bool_Exp>>>;
  _not?: Maybe<Group_Profil_Points_And_Rank_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Group_Profil_Points_And_Rank_Bool_Exp>>>;
  cup?: Maybe<Cup_Bool_Exp>;
  cup_id?: Maybe<Uuid_Comparison_Exp>;
  group?: Maybe<Group_Bool_Exp>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  miss?: Maybe<Bigint_Comparison_Exp>;
  partial?: Maybe<Bigint_Comparison_Exp>;
  perfect?: Maybe<Bigint_Comparison_Exp>;
  point_user?: Maybe<Bigint_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profile_id?: Maybe<Uuid_Comparison_Exp>;
  rank?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Group_Profil_Points_And_Rank_Max_Fields = {
  __typename?: 'group_profil_points_and_rank_max_fields';
  cup_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
  miss?: Maybe<Scalars['bigint']>;
  partial?: Maybe<Scalars['bigint']>;
  perfect?: Maybe<Scalars['bigint']>;
  point_user?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Max_Order_By = {
  cup_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Group_Profil_Points_And_Rank_Min_Fields = {
  __typename?: 'group_profil_points_and_rank_min_fields';
  cup_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
  miss?: Maybe<Scalars['bigint']>;
  partial?: Maybe<Scalars['bigint']>;
  perfect?: Maybe<Scalars['bigint']>;
  point_user?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Min_Order_By = {
  cup_id?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** ordering options when selecting data from "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Order_By = {
  cup?: Maybe<Cup_Order_By>;
  cup_id?: Maybe<Order_By>;
  group?: Maybe<Group_Order_By>;
  group_id?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profile_id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** select columns of table "group_profil_points_and_rank" */
export enum Group_Profil_Points_And_Rank_Select_Column {
  /** column name */
  CupId = 'cup_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Miss = 'miss',
  /** column name */
  Partial = 'partial',
  /** column name */
  Perfect = 'perfect',
  /** column name */
  PointUser = 'point_user',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Rank = 'rank',
}

/** aggregate stddev on columns */
export type Group_Profil_Points_And_Rank_Stddev_Fields = {
  __typename?: 'group_profil_points_and_rank_stddev_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  point_user?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Stddev_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Group_Profil_Points_And_Rank_Stddev_Pop_Fields = {
  __typename?: 'group_profil_points_and_rank_stddev_pop_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  point_user?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Stddev_Pop_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Group_Profil_Points_And_Rank_Stddev_Samp_Fields = {
  __typename?: 'group_profil_points_and_rank_stddev_samp_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  point_user?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Stddev_Samp_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Group_Profil_Points_And_Rank_Sum_Fields = {
  __typename?: 'group_profil_points_and_rank_sum_fields';
  miss?: Maybe<Scalars['bigint']>;
  partial?: Maybe<Scalars['bigint']>;
  perfect?: Maybe<Scalars['bigint']>;
  point_user?: Maybe<Scalars['bigint']>;
  rank?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Sum_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Group_Profil_Points_And_Rank_Var_Pop_Fields = {
  __typename?: 'group_profil_points_and_rank_var_pop_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  point_user?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Var_Pop_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Group_Profil_Points_And_Rank_Var_Samp_Fields = {
  __typename?: 'group_profil_points_and_rank_var_samp_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  point_user?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Var_Samp_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Group_Profil_Points_And_Rank_Variance_Fields = {
  __typename?: 'group_profil_points_and_rank_variance_fields';
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
  point_user?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "group_profil_points_and_rank" */
export type Group_Profil_Points_And_Rank_Variance_Order_By = {
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  point_user?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
};

/** select columns of table "group" */
export enum Group_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CupId = 'cup_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "group" */
export type Group_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  cup_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "group" */
export enum Group_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CupId = 'cup_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};

/** columns and relationships of "match" */
export type Match = {
  __typename?: 'match';
  awayScore?: Maybe<Scalars['Int']>;
  /** An array relationship */
  forecasts: Array<Forecast>;
  /** An aggregated array relationship */
  forecasts_aggregate: Forecast_Aggregate;
  homeScore?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  isMatchOver: Scalars['Boolean'];
  /** An object relationship */
  matchday?: Maybe<Matchday>;
  matchday_id?: Maybe<Scalars['uuid']>;
  playedAt: Scalars['timestamptz'];
  /** An object relationship */
  pool: Pool;
  poolId: Scalars['uuid'];
  /** An object relationship */
  teamAway?: Maybe<Team_Cup>;
  teamAwayId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  teamHome?: Maybe<Team_Cup>;
  teamHomeId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "match" */
export type MatchForecastsArgs = {
  distinct_on?: Maybe<Array<Forecast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Order_By>>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** columns and relationships of "match" */
export type MatchForecasts_AggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Order_By>>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** aggregated selection of "match" */
export type Match_Aggregate = {
  __typename?: 'match_aggregate';
  aggregate?: Maybe<Match_Aggregate_Fields>;
  nodes: Array<Match>;
};

/** aggregate fields of "match" */
export type Match_Aggregate_Fields = {
  __typename?: 'match_aggregate_fields';
  avg?: Maybe<Match_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Match_Max_Fields>;
  min?: Maybe<Match_Min_Fields>;
  stddev?: Maybe<Match_Stddev_Fields>;
  stddev_pop?: Maybe<Match_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Match_Stddev_Samp_Fields>;
  sum?: Maybe<Match_Sum_Fields>;
  var_pop?: Maybe<Match_Var_Pop_Fields>;
  var_samp?: Maybe<Match_Var_Samp_Fields>;
  variance?: Maybe<Match_Variance_Fields>;
};

/** aggregate fields of "match" */
export type Match_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Match_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "match" */
export type Match_Aggregate_Order_By = {
  avg?: Maybe<Match_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Match_Max_Order_By>;
  min?: Maybe<Match_Min_Order_By>;
  stddev?: Maybe<Match_Stddev_Order_By>;
  stddev_pop?: Maybe<Match_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Match_Stddev_Samp_Order_By>;
  sum?: Maybe<Match_Sum_Order_By>;
  var_pop?: Maybe<Match_Var_Pop_Order_By>;
  var_samp?: Maybe<Match_Var_Samp_Order_By>;
  variance?: Maybe<Match_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "match" */
export type Match_Arr_Rel_Insert_Input = {
  data: Array<Match_Insert_Input>;
  on_conflict?: Maybe<Match_On_Conflict>;
};

/** aggregate avg on columns */
export type Match_Avg_Fields = {
  __typename?: 'match_avg_fields';
  awayScore?: Maybe<Scalars['Float']>;
  homeScore?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "match" */
export type Match_Avg_Order_By = {
  awayScore?: Maybe<Order_By>;
  homeScore?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "match". All fields are combined with a logical 'AND'. */
export type Match_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Match_Bool_Exp>>>;
  _not?: Maybe<Match_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Match_Bool_Exp>>>;
  awayScore?: Maybe<Int_Comparison_Exp>;
  forecasts?: Maybe<Forecast_Bool_Exp>;
  homeScore?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isMatchOver?: Maybe<Boolean_Comparison_Exp>;
  matchday?: Maybe<Matchday_Bool_Exp>;
  matchday_id?: Maybe<Uuid_Comparison_Exp>;
  playedAt?: Maybe<Timestamptz_Comparison_Exp>;
  pool?: Maybe<Pool_Bool_Exp>;
  poolId?: Maybe<Uuid_Comparison_Exp>;
  teamAway?: Maybe<Team_Cup_Bool_Exp>;
  teamAwayId?: Maybe<Uuid_Comparison_Exp>;
  teamHome?: Maybe<Team_Cup_Bool_Exp>;
  teamHomeId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "match" */
export enum Match_Constraint {
  /** unique or primary key constraint */
  MatchesPkey = 'matches_pkey',
}

/** input type for incrementing integer column in table "match" */
export type Match_Inc_Input = {
  awayScore?: Maybe<Scalars['Int']>;
  homeScore?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "match" */
export type Match_Insert_Input = {
  awayScore?: Maybe<Scalars['Int']>;
  forecasts?: Maybe<Forecast_Arr_Rel_Insert_Input>;
  homeScore?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  isMatchOver?: Maybe<Scalars['Boolean']>;
  matchday?: Maybe<Matchday_Obj_Rel_Insert_Input>;
  matchday_id?: Maybe<Scalars['uuid']>;
  playedAt?: Maybe<Scalars['timestamptz']>;
  pool?: Maybe<Pool_Obj_Rel_Insert_Input>;
  poolId?: Maybe<Scalars['uuid']>;
  teamAway?: Maybe<Team_Cup_Obj_Rel_Insert_Input>;
  teamAwayId?: Maybe<Scalars['uuid']>;
  teamHome?: Maybe<Team_Cup_Obj_Rel_Insert_Input>;
  teamHomeId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Match_Max_Fields = {
  __typename?: 'match_max_fields';
  awayScore?: Maybe<Scalars['Int']>;
  homeScore?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  matchday_id?: Maybe<Scalars['uuid']>;
  playedAt?: Maybe<Scalars['timestamptz']>;
  poolId?: Maybe<Scalars['uuid']>;
  teamAwayId?: Maybe<Scalars['uuid']>;
  teamHomeId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "match" */
export type Match_Max_Order_By = {
  awayScore?: Maybe<Order_By>;
  homeScore?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  matchday_id?: Maybe<Order_By>;
  playedAt?: Maybe<Order_By>;
  poolId?: Maybe<Order_By>;
  teamAwayId?: Maybe<Order_By>;
  teamHomeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Match_Min_Fields = {
  __typename?: 'match_min_fields';
  awayScore?: Maybe<Scalars['Int']>;
  homeScore?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  matchday_id?: Maybe<Scalars['uuid']>;
  playedAt?: Maybe<Scalars['timestamptz']>;
  poolId?: Maybe<Scalars['uuid']>;
  teamAwayId?: Maybe<Scalars['uuid']>;
  teamHomeId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "match" */
export type Match_Min_Order_By = {
  awayScore?: Maybe<Order_By>;
  homeScore?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  matchday_id?: Maybe<Order_By>;
  playedAt?: Maybe<Order_By>;
  poolId?: Maybe<Order_By>;
  teamAwayId?: Maybe<Order_By>;
  teamHomeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "match" */
export type Match_Mutation_Response = {
  __typename?: 'match_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Match>;
};

/** input type for inserting object relation for remote table "match" */
export type Match_Obj_Rel_Insert_Input = {
  data: Match_Insert_Input;
  on_conflict?: Maybe<Match_On_Conflict>;
};

/** on conflict condition type for table "match" */
export type Match_On_Conflict = {
  constraint: Match_Constraint;
  update_columns: Array<Match_Update_Column>;
  where?: Maybe<Match_Bool_Exp>;
};

/** ordering options when selecting data from "match" */
export type Match_Order_By = {
  awayScore?: Maybe<Order_By>;
  forecasts_aggregate?: Maybe<Forecast_Aggregate_Order_By>;
  homeScore?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isMatchOver?: Maybe<Order_By>;
  matchday?: Maybe<Matchday_Order_By>;
  matchday_id?: Maybe<Order_By>;
  playedAt?: Maybe<Order_By>;
  pool?: Maybe<Pool_Order_By>;
  poolId?: Maybe<Order_By>;
  teamAway?: Maybe<Team_Cup_Order_By>;
  teamAwayId?: Maybe<Order_By>;
  teamHome?: Maybe<Team_Cup_Order_By>;
  teamHomeId?: Maybe<Order_By>;
};

/** primary key columns input for table: "match" */
export type Match_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "match" */
export enum Match_Select_Column {
  /** column name */
  AwayScore = 'awayScore',
  /** column name */
  HomeScore = 'homeScore',
  /** column name */
  Id = 'id',
  /** column name */
  IsMatchOver = 'isMatchOver',
  /** column name */
  MatchdayId = 'matchday_id',
  /** column name */
  PlayedAt = 'playedAt',
  /** column name */
  PoolId = 'poolId',
  /** column name */
  TeamAwayId = 'teamAwayId',
  /** column name */
  TeamHomeId = 'teamHomeId',
}

/** input type for updating data in table "match" */
export type Match_Set_Input = {
  awayScore?: Maybe<Scalars['Int']>;
  homeScore?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  isMatchOver?: Maybe<Scalars['Boolean']>;
  matchday_id?: Maybe<Scalars['uuid']>;
  playedAt?: Maybe<Scalars['timestamptz']>;
  poolId?: Maybe<Scalars['uuid']>;
  teamAwayId?: Maybe<Scalars['uuid']>;
  teamHomeId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Match_Stddev_Fields = {
  __typename?: 'match_stddev_fields';
  awayScore?: Maybe<Scalars['Float']>;
  homeScore?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "match" */
export type Match_Stddev_Order_By = {
  awayScore?: Maybe<Order_By>;
  homeScore?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Match_Stddev_Pop_Fields = {
  __typename?: 'match_stddev_pop_fields';
  awayScore?: Maybe<Scalars['Float']>;
  homeScore?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "match" */
export type Match_Stddev_Pop_Order_By = {
  awayScore?: Maybe<Order_By>;
  homeScore?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Match_Stddev_Samp_Fields = {
  __typename?: 'match_stddev_samp_fields';
  awayScore?: Maybe<Scalars['Float']>;
  homeScore?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "match" */
export type Match_Stddev_Samp_Order_By = {
  awayScore?: Maybe<Order_By>;
  homeScore?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Match_Sum_Fields = {
  __typename?: 'match_sum_fields';
  awayScore?: Maybe<Scalars['Int']>;
  homeScore?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "match" */
export type Match_Sum_Order_By = {
  awayScore?: Maybe<Order_By>;
  homeScore?: Maybe<Order_By>;
};

/** update columns of table "match" */
export enum Match_Update_Column {
  /** column name */
  AwayScore = 'awayScore',
  /** column name */
  HomeScore = 'homeScore',
  /** column name */
  Id = 'id',
  /** column name */
  IsMatchOver = 'isMatchOver',
  /** column name */
  MatchdayId = 'matchday_id',
  /** column name */
  PlayedAt = 'playedAt',
  /** column name */
  PoolId = 'poolId',
  /** column name */
  TeamAwayId = 'teamAwayId',
  /** column name */
  TeamHomeId = 'teamHomeId',
}

/** aggregate var_pop on columns */
export type Match_Var_Pop_Fields = {
  __typename?: 'match_var_pop_fields';
  awayScore?: Maybe<Scalars['Float']>;
  homeScore?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "match" */
export type Match_Var_Pop_Order_By = {
  awayScore?: Maybe<Order_By>;
  homeScore?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Match_Var_Samp_Fields = {
  __typename?: 'match_var_samp_fields';
  awayScore?: Maybe<Scalars['Float']>;
  homeScore?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "match" */
export type Match_Var_Samp_Order_By = {
  awayScore?: Maybe<Order_By>;
  homeScore?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Match_Variance_Fields = {
  __typename?: 'match_variance_fields';
  awayScore?: Maybe<Scalars['Float']>;
  homeScore?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "match" */
export type Match_Variance_Order_By = {
  awayScore?: Maybe<Order_By>;
  homeScore?: Maybe<Order_By>;
};

/** columns and relationships of "matchday" */
export type Matchday = {
  __typename?: 'matchday';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  cup: Cup;
  cup_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An array relationship */
  matches: Array<Match>;
  /** An aggregated array relationship */
  matches_aggregate: Match_Aggregate;
  number: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "matchday" */
export type MatchdayMatchesArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** columns and relationships of "matchday" */
export type MatchdayMatches_AggregateArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** aggregated selection of "matchday" */
export type Matchday_Aggregate = {
  __typename?: 'matchday_aggregate';
  aggregate?: Maybe<Matchday_Aggregate_Fields>;
  nodes: Array<Matchday>;
};

/** aggregate fields of "matchday" */
export type Matchday_Aggregate_Fields = {
  __typename?: 'matchday_aggregate_fields';
  avg?: Maybe<Matchday_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Matchday_Max_Fields>;
  min?: Maybe<Matchday_Min_Fields>;
  stddev?: Maybe<Matchday_Stddev_Fields>;
  stddev_pop?: Maybe<Matchday_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matchday_Stddev_Samp_Fields>;
  sum?: Maybe<Matchday_Sum_Fields>;
  var_pop?: Maybe<Matchday_Var_Pop_Fields>;
  var_samp?: Maybe<Matchday_Var_Samp_Fields>;
  variance?: Maybe<Matchday_Variance_Fields>;
};

/** aggregate fields of "matchday" */
export type Matchday_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Matchday_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "matchday" */
export type Matchday_Aggregate_Order_By = {
  avg?: Maybe<Matchday_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Matchday_Max_Order_By>;
  min?: Maybe<Matchday_Min_Order_By>;
  stddev?: Maybe<Matchday_Stddev_Order_By>;
  stddev_pop?: Maybe<Matchday_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Matchday_Stddev_Samp_Order_By>;
  sum?: Maybe<Matchday_Sum_Order_By>;
  var_pop?: Maybe<Matchday_Var_Pop_Order_By>;
  var_samp?: Maybe<Matchday_Var_Samp_Order_By>;
  variance?: Maybe<Matchday_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "matchday" */
export type Matchday_Arr_Rel_Insert_Input = {
  data: Array<Matchday_Insert_Input>;
  on_conflict?: Maybe<Matchday_On_Conflict>;
};

/** aggregate avg on columns */
export type Matchday_Avg_Fields = {
  __typename?: 'matchday_avg_fields';
  number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "matchday" */
export type Matchday_Avg_Order_By = {
  number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "matchday". All fields are combined with a logical 'AND'. */
export type Matchday_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Matchday_Bool_Exp>>>;
  _not?: Maybe<Matchday_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Matchday_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cup?: Maybe<Cup_Bool_Exp>;
  cup_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  matches?: Maybe<Match_Bool_Exp>;
  number?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "matchday" */
export enum Matchday_Constraint {
  /** unique or primary key constraint */
  MatchdayCupIdNumberKey = 'matchday_cup_id_number_key',
  /** unique or primary key constraint */
  MatchdayPkey = 'matchday_pkey',
}

/** input type for incrementing integer column in table "matchday" */
export type Matchday_Inc_Input = {
  number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "matchday" */
export type Matchday_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  cup?: Maybe<Cup_Obj_Rel_Insert_Input>;
  cup_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  matches?: Maybe<Match_Arr_Rel_Insert_Input>;
  number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Matchday_Max_Fields = {
  __typename?: 'matchday_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cup_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "matchday" */
export type Matchday_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  cup_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Matchday_Min_Fields = {
  __typename?: 'matchday_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cup_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "matchday" */
export type Matchday_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  cup_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "matchday" */
export type Matchday_Mutation_Response = {
  __typename?: 'matchday_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Matchday>;
};

/** input type for inserting object relation for remote table "matchday" */
export type Matchday_Obj_Rel_Insert_Input = {
  data: Matchday_Insert_Input;
  on_conflict?: Maybe<Matchday_On_Conflict>;
};

/** on conflict condition type for table "matchday" */
export type Matchday_On_Conflict = {
  constraint: Matchday_Constraint;
  update_columns: Array<Matchday_Update_Column>;
  where?: Maybe<Matchday_Bool_Exp>;
};

/** ordering options when selecting data from "matchday" */
export type Matchday_Order_By = {
  created_at?: Maybe<Order_By>;
  cup?: Maybe<Cup_Order_By>;
  cup_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  matches_aggregate?: Maybe<Match_Aggregate_Order_By>;
  number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "matchday" */
export type Matchday_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "matchday" */
export enum Matchday_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CupId = 'cup_id',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "matchday" */
export type Matchday_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  cup_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Matchday_Stddev_Fields = {
  __typename?: 'matchday_stddev_fields';
  number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "matchday" */
export type Matchday_Stddev_Order_By = {
  number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Matchday_Stddev_Pop_Fields = {
  __typename?: 'matchday_stddev_pop_fields';
  number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "matchday" */
export type Matchday_Stddev_Pop_Order_By = {
  number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Matchday_Stddev_Samp_Fields = {
  __typename?: 'matchday_stddev_samp_fields';
  number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "matchday" */
export type Matchday_Stddev_Samp_Order_By = {
  number?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Matchday_Sum_Fields = {
  __typename?: 'matchday_sum_fields';
  number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "matchday" */
export type Matchday_Sum_Order_By = {
  number?: Maybe<Order_By>;
};

/** update columns of table "matchday" */
export enum Matchday_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CupId = 'cup_id',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate var_pop on columns */
export type Matchday_Var_Pop_Fields = {
  __typename?: 'matchday_var_pop_fields';
  number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "matchday" */
export type Matchday_Var_Pop_Order_By = {
  number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Matchday_Var_Samp_Fields = {
  __typename?: 'matchday_var_samp_fields';
  number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "matchday" */
export type Matchday_Var_Samp_Order_By = {
  number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Matchday_Variance_Fields = {
  __typename?: 'matchday_variance_fields';
  number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "matchday" */
export type Matchday_Variance_Order_By = {
  number?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** perform the action: "acceptInvitation" */
  acceptInvitation?: Maybe<AcceptInvitationOutput>;
  /** perform the action: "createGroupInvitationLink" */
  createGroupInvitationLink?: Maybe<CreateGroupInvitationLinkOutput>;
  /** delete data from the table: "cup" */
  deleteCup?: Maybe<Cup_Mutation_Response>;
  /** delete single row from the table: "cup" */
  deleteCupByPk?: Maybe<Cup>;
  /** delete data from the table: "forecast" */
  deleteForecast?: Maybe<Forecast_Mutation_Response>;
  /** delete single row from the table: "forecast" */
  deleteForecastByPk?: Maybe<Forecast>;
  /** delete data from the table: "match" */
  deleteMatch?: Maybe<Match_Mutation_Response>;
  /** delete single row from the table: "match" */
  deleteMatchByPk?: Maybe<Match>;
  /** delete data from the table: "player_team" */
  deletePlayerTeam?: Maybe<Player_Team_Mutation_Response>;
  /** delete single row from the table: "player_team" */
  deletePlayerTeamByPk?: Maybe<Player_Team>;
  /** delete data from the table: "pool" */
  deletePool?: Maybe<Pool_Mutation_Response>;
  /** delete single row from the table: "pool" */
  deletePoolByPk?: Maybe<Pool>;
  /** delete data from the table: "pool_types" */
  deletePoolTypes?: Maybe<Pool_Types_Mutation_Response>;
  /** delete single row from the table: "pool_types" */
  deletePoolTypesByPk?: Maybe<Pool_Types>;
  /** delete data from the table: "profile" */
  deleteProfile?: Maybe<Profile_Mutation_Response>;
  /** delete single row from the table: "profile" */
  deleteProfileByPk?: Maybe<Profile>;
  /** delete data from the table: "team_cup" */
  deleteTeamCup?: Maybe<Team_Cup_Mutation_Response>;
  /** delete single row from the table: "team_cup" */
  deleteTeamCupByPk?: Maybe<Team_Cup>;
  /** delete data from the table: "user" */
  deleteUser?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  deleteUserByPk?: Maybe<User>;
  /** delete data from the table: "forecast_status_points" */
  delete_forecast_status_points?: Maybe<Forecast_Status_Points_Mutation_Response>;
  /** delete single row from the table: "forecast_status_points" */
  delete_forecast_status_points_by_pk?: Maybe<Forecast_Status_Points>;
  /** delete data from the table: "group" */
  delete_group?: Maybe<Group_Mutation_Response>;
  /** delete single row from the table: "group" */
  delete_group_by_pk?: Maybe<Group>;
  /** delete data from the table: "group_invite" */
  delete_group_invite?: Maybe<Group_Invite_Mutation_Response>;
  /** delete single row from the table: "group_invite" */
  delete_group_invite_by_pk?: Maybe<Group_Invite>;
  /** delete data from the table: "group_member" */
  delete_group_member?: Maybe<Group_Member_Mutation_Response>;
  /** delete single row from the table: "group_member" */
  delete_group_member_by_pk?: Maybe<Group_Member>;
  /** delete data from the table: "matchday" */
  delete_matchday?: Maybe<Matchday_Mutation_Response>;
  /** delete single row from the table: "matchday" */
  delete_matchday_by_pk?: Maybe<Matchday>;
  /** delete data from the table: "used_pseudo" */
  delete_used_pseudo?: Maybe<Used_Pseudo_Mutation_Response>;
  /** delete data from the table: "user_personnal_data" */
  delete_user_personnal_data?: Maybe<User_Personnal_Data_Mutation_Response>;
  /** delete data from the table: "forecast_status" */
  deleteforecastStatus?: Maybe<Forecast_Status_Mutation_Response>;
  /** delete single row from the table: "forecast_status" */
  deleteforecastStatusByPk?: Maybe<Forecast_Status>;
  /** insert data into the table: "cup" */
  insertCup?: Maybe<Cup_Mutation_Response>;
  /** insert a single row into the table: "cup" */
  insertCupOne?: Maybe<Cup>;
  /** insert data into the table: "forecast" */
  insertForecast?: Maybe<Forecast_Mutation_Response>;
  /** insert a single row into the table: "forecast" */
  insertForecastOne?: Maybe<Forecast>;
  /** insert data into the table: "match" */
  insertMatch?: Maybe<Match_Mutation_Response>;
  /** insert a single row into the table: "match" */
  insertMatchOne?: Maybe<Match>;
  /** insert data into the table: "player_team" */
  insertPlayerTeam?: Maybe<Player_Team_Mutation_Response>;
  /** insert a single row into the table: "player_team" */
  insertPlayerTeamOne?: Maybe<Player_Team>;
  /** insert data into the table: "pool" */
  insertPool?: Maybe<Pool_Mutation_Response>;
  /** insert a single row into the table: "pool" */
  insertPoolOne?: Maybe<Pool>;
  /** insert data into the table: "pool_types" */
  insertPoolTypes?: Maybe<Pool_Types_Mutation_Response>;
  /** insert a single row into the table: "pool_types" */
  insertPoolTypesOne?: Maybe<Pool_Types>;
  /** insert data into the table: "profile" */
  insertProfile?: Maybe<Profile_Mutation_Response>;
  /** insert a single row into the table: "profile" */
  insertProfileOne?: Maybe<Profile>;
  /** insert data into the table: "team_cup" */
  insertTeamCup?: Maybe<Team_Cup_Mutation_Response>;
  /** insert a single row into the table: "team_cup" */
  insertTeamCupOne?: Maybe<Team_Cup>;
  /** insert data into the table: "user" */
  insertUser?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insertUserOne?: Maybe<User>;
  /** insert data into the table: "forecast_status_points" */
  insert_forecast_status_points?: Maybe<Forecast_Status_Points_Mutation_Response>;
  /** insert a single row into the table: "forecast_status_points" */
  insert_forecast_status_points_one?: Maybe<Forecast_Status_Points>;
  /** insert data into the table: "group" */
  insert_group?: Maybe<Group_Mutation_Response>;
  /** insert data into the table: "group_invite" */
  insert_group_invite?: Maybe<Group_Invite_Mutation_Response>;
  /** insert a single row into the table: "group_invite" */
  insert_group_invite_one?: Maybe<Group_Invite>;
  /** insert data into the table: "group_member" */
  insert_group_member?: Maybe<Group_Member_Mutation_Response>;
  /** insert a single row into the table: "group_member" */
  insert_group_member_one?: Maybe<Group_Member>;
  /** insert a single row into the table: "group" */
  insert_group_one?: Maybe<Group>;
  /** insert data into the table: "matchday" */
  insert_matchday?: Maybe<Matchday_Mutation_Response>;
  /** insert a single row into the table: "matchday" */
  insert_matchday_one?: Maybe<Matchday>;
  /** insert data into the table: "user_personnal_data" */
  insert_user_personnal_data?: Maybe<User_Personnal_Data_Mutation_Response>;
  /** insert a single row into the table: "user_personnal_data" */
  insert_user_personnal_data_one?: Maybe<User_Personnal_Data>;
  /** insert data into the table: "forecast_status" */
  insertforecastStatus?: Maybe<Forecast_Status_Mutation_Response>;
  /** insert a single row into the table: "forecast_status" */
  insertforecastStatusOne?: Maybe<Forecast_Status>;
  /** perform the action: "makeForecast" */
  makeForecast?: Maybe<MakeForecastOutput>;
  /** perform the action: "registerGroup" */
  registerGroup?: Maybe<RegisterGroupOutput>;
  /** perform the action: "registerPseudo" */
  registerPseudo?: Maybe<RegisterPseudoOutput>;
  /** perform the action: "setUserModeratorState" */
  setUserModeratorState?: Maybe<SetUserModeratorStateOutput>;
  /** update data of the table: "cup" */
  updateCup?: Maybe<Cup_Mutation_Response>;
  /** update single row of the table: "cup" */
  updateCupByPk?: Maybe<Cup>;
  /** update data of the table: "forecast" */
  updateForecast?: Maybe<Forecast_Mutation_Response>;
  /** update single row of the table: "forecast" */
  updateForecastByPk?: Maybe<Forecast>;
  /** perform the action: "updateGroupInfo" */
  updateGroupInfo?: Maybe<UpdateGroupInfoOutput>;
  /** update data of the table: "match" */
  updateMatch?: Maybe<Match_Mutation_Response>;
  /** update single row of the table: "match" */
  updateMatchByPk?: Maybe<Match>;
  /** update data of the table: "player_team" */
  updatePlayerTeam?: Maybe<Player_Team_Mutation_Response>;
  /** update single row of the table: "player_team" */
  updatePlayerTeamByPk?: Maybe<Player_Team>;
  /** update data of the table: "pool" */
  updatePool?: Maybe<Pool_Mutation_Response>;
  /** update single row of the table: "pool" */
  updatePoolByPk?: Maybe<Pool>;
  /** update data of the table: "pool_types" */
  updatePoolTypes?: Maybe<Pool_Types_Mutation_Response>;
  /** update single row of the table: "pool_types" */
  updatePoolTypesByPk?: Maybe<Pool_Types>;
  /** update data of the table: "profile" */
  updateProfile?: Maybe<Profile_Mutation_Response>;
  /** update single row of the table: "profile" */
  updateProfileByPk?: Maybe<Profile>;
  /** update data of the table: "team_cup" */
  updateTeamCup?: Maybe<Team_Cup_Mutation_Response>;
  /** update single row of the table: "team_cup" */
  updateTeamCupByPk?: Maybe<Team_Cup>;
  /** update data of the table: "user" */
  updateUser?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  updateUserByPk?: Maybe<User>;
  /** update data of the table: "forecast_status_points" */
  update_forecast_status_points?: Maybe<Forecast_Status_Points_Mutation_Response>;
  /** update single row of the table: "forecast_status_points" */
  update_forecast_status_points_by_pk?: Maybe<Forecast_Status_Points>;
  /** update data of the table: "group" */
  update_group?: Maybe<Group_Mutation_Response>;
  /** update single row of the table: "group" */
  update_group_by_pk?: Maybe<Group>;
  /** update data of the table: "group_invite" */
  update_group_invite?: Maybe<Group_Invite_Mutation_Response>;
  /** update single row of the table: "group_invite" */
  update_group_invite_by_pk?: Maybe<Group_Invite>;
  /** update data of the table: "group_member" */
  update_group_member?: Maybe<Group_Member_Mutation_Response>;
  /** update single row of the table: "group_member" */
  update_group_member_by_pk?: Maybe<Group_Member>;
  /** update data of the table: "matchday" */
  update_matchday?: Maybe<Matchday_Mutation_Response>;
  /** update single row of the table: "matchday" */
  update_matchday_by_pk?: Maybe<Matchday>;
  /** update data of the table: "user_personnal_data" */
  update_user_personnal_data?: Maybe<User_Personnal_Data_Mutation_Response>;
  /** update data of the table: "forecast_status" */
  updateforecastStatus?: Maybe<Forecast_Status_Mutation_Response>;
  /** update single row of the table: "forecast_status" */
  updateforecastStatusByPk?: Maybe<Forecast_Status>;
};

/** mutation root */
export type Mutation_RootAcceptInvitationArgs = {
  invitationId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateGroupInvitationLinkArgs = {
  groupId: Scalars['uuid'];
  nmbDaysOpen: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDeleteCupArgs = {
  where: Cup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteCupByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteForecastArgs = {
  where: Forecast_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteForecastByPkArgs = {
  matchId: Scalars['uuid'];
  profileId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteMatchArgs = {
  where: Match_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteMatchByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeletePlayerTeamArgs = {
  where: Player_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeletePlayerTeamByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeletePoolArgs = {
  where: Pool_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeletePoolByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeletePoolTypesArgs = {
  where: Pool_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeletePoolTypesByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteProfileArgs = {
  where: Profile_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteProfileByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTeamCupArgs = {
  where: Team_Cup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteTeamCupByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteUserByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Forecast_Status_PointsArgs = {
  where: Forecast_Status_Points_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Forecast_Status_Points_By_PkArgs = {
  forecast_status: Forecast_Status_Enum;
};

/** mutation root */
export type Mutation_RootDelete_GroupArgs = {
  where: Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Group_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Group_InviteArgs = {
  where: Group_Invite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Group_Invite_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Group_MemberArgs = {
  where: Group_Member_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Group_Member_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_MatchdayArgs = {
  where: Matchday_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Matchday_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Used_PseudoArgs = {
  where: Used_Pseudo_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Personnal_DataArgs = {
  where: User_Personnal_Data_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteforecastStatusArgs = {
  where: Forecast_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteforecastStatusByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootInsertCupArgs = {
  objects: Array<Cup_Insert_Input>;
  on_conflict?: Maybe<Cup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertCupOneArgs = {
  object: Cup_Insert_Input;
  on_conflict?: Maybe<Cup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertForecastArgs = {
  objects: Array<Forecast_Insert_Input>;
  on_conflict?: Maybe<Forecast_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertForecastOneArgs = {
  object: Forecast_Insert_Input;
  on_conflict?: Maybe<Forecast_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertMatchArgs = {
  objects: Array<Match_Insert_Input>;
  on_conflict?: Maybe<Match_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertMatchOneArgs = {
  object: Match_Insert_Input;
  on_conflict?: Maybe<Match_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertPlayerTeamArgs = {
  objects: Array<Player_Team_Insert_Input>;
  on_conflict?: Maybe<Player_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertPlayerTeamOneArgs = {
  object: Player_Team_Insert_Input;
  on_conflict?: Maybe<Player_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertPoolArgs = {
  objects: Array<Pool_Insert_Input>;
  on_conflict?: Maybe<Pool_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertPoolOneArgs = {
  object: Pool_Insert_Input;
  on_conflict?: Maybe<Pool_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertPoolTypesArgs = {
  objects: Array<Pool_Types_Insert_Input>;
  on_conflict?: Maybe<Pool_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertPoolTypesOneArgs = {
  object: Pool_Types_Insert_Input;
  on_conflict?: Maybe<Pool_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertProfileArgs = {
  objects: Array<Profile_Insert_Input>;
  on_conflict?: Maybe<Profile_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertProfileOneArgs = {
  object: Profile_Insert_Input;
  on_conflict?: Maybe<Profile_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamCupArgs = {
  objects: Array<Team_Cup_Insert_Input>;
  on_conflict?: Maybe<Team_Cup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamCupOneArgs = {
  object: Team_Cup_Insert_Input;
  on_conflict?: Maybe<Team_Cup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertUserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertUserOneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Forecast_Status_PointsArgs = {
  objects: Array<Forecast_Status_Points_Insert_Input>;
  on_conflict?: Maybe<Forecast_Status_Points_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Forecast_Status_Points_OneArgs = {
  object: Forecast_Status_Points_Insert_Input;
  on_conflict?: Maybe<Forecast_Status_Points_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GroupArgs = {
  objects: Array<Group_Insert_Input>;
  on_conflict?: Maybe<Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Group_InviteArgs = {
  objects: Array<Group_Invite_Insert_Input>;
  on_conflict?: Maybe<Group_Invite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Group_Invite_OneArgs = {
  object: Group_Invite_Insert_Input;
  on_conflict?: Maybe<Group_Invite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Group_MemberArgs = {
  objects: Array<Group_Member_Insert_Input>;
  on_conflict?: Maybe<Group_Member_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Group_Member_OneArgs = {
  object: Group_Member_Insert_Input;
  on_conflict?: Maybe<Group_Member_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Group_OneArgs = {
  object: Group_Insert_Input;
  on_conflict?: Maybe<Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MatchdayArgs = {
  objects: Array<Matchday_Insert_Input>;
  on_conflict?: Maybe<Matchday_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Matchday_OneArgs = {
  object: Matchday_Insert_Input;
  on_conflict?: Maybe<Matchday_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Personnal_DataArgs = {
  objects: Array<User_Personnal_Data_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_User_Personnal_Data_OneArgs = {
  object: User_Personnal_Data_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsertforecastStatusArgs = {
  objects: Array<Forecast_Status_Insert_Input>;
  on_conflict?: Maybe<Forecast_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertforecastStatusOneArgs = {
  object: Forecast_Status_Insert_Input;
  on_conflict?: Maybe<Forecast_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootMakeForecastArgs = {
  awayScore: Scalars['Int'];
  homeScore: Scalars['Int'];
  matchId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootRegisterGroupArgs = {
  cupId: Scalars['uuid'];
  groupName: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootRegisterPseudoArgs = {
  pseudo: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSetUserModeratorStateArgs = {
  isModerator: Scalars['Boolean'];
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootUpdateCupArgs = {
  _set?: Maybe<Cup_Set_Input>;
  where: Cup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateCupByPkArgs = {
  _set?: Maybe<Cup_Set_Input>;
  pk_columns: Cup_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateForecastArgs = {
  _inc?: Maybe<Forecast_Inc_Input>;
  _set?: Maybe<Forecast_Set_Input>;
  where: Forecast_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateForecastByPkArgs = {
  _inc?: Maybe<Forecast_Inc_Input>;
  _set?: Maybe<Forecast_Set_Input>;
  pk_columns: Forecast_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateGroupInfoArgs = {
  groupId: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootUpdateMatchArgs = {
  _inc?: Maybe<Match_Inc_Input>;
  _set?: Maybe<Match_Set_Input>;
  where: Match_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateMatchByPkArgs = {
  _inc?: Maybe<Match_Inc_Input>;
  _set?: Maybe<Match_Set_Input>;
  pk_columns: Match_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdatePlayerTeamArgs = {
  _set?: Maybe<Player_Team_Set_Input>;
  where: Player_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdatePlayerTeamByPkArgs = {
  _set?: Maybe<Player_Team_Set_Input>;
  pk_columns: Player_Team_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdatePoolArgs = {
  _inc?: Maybe<Pool_Inc_Input>;
  _set?: Maybe<Pool_Set_Input>;
  where: Pool_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdatePoolByPkArgs = {
  _inc?: Maybe<Pool_Inc_Input>;
  _set?: Maybe<Pool_Set_Input>;
  pk_columns: Pool_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdatePoolTypesArgs = {
  _set?: Maybe<Pool_Types_Set_Input>;
  where: Pool_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdatePoolTypesByPkArgs = {
  _set?: Maybe<Pool_Types_Set_Input>;
  pk_columns: Pool_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateProfileArgs = {
  _set?: Maybe<Profile_Set_Input>;
  where: Profile_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateProfileByPkArgs = {
  _set?: Maybe<Profile_Set_Input>;
  pk_columns: Profile_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateTeamCupArgs = {
  _set?: Maybe<Team_Cup_Set_Input>;
  where: Team_Cup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateTeamCupByPkArgs = {
  _set?: Maybe<Team_Cup_Set_Input>;
  pk_columns: Team_Cup_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateUserByPkArgs = {
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Forecast_Status_PointsArgs = {
  _inc?: Maybe<Forecast_Status_Points_Inc_Input>;
  _set?: Maybe<Forecast_Status_Points_Set_Input>;
  where: Forecast_Status_Points_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Forecast_Status_Points_By_PkArgs = {
  _inc?: Maybe<Forecast_Status_Points_Inc_Input>;
  _set?: Maybe<Forecast_Status_Points_Set_Input>;
  pk_columns: Forecast_Status_Points_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GroupArgs = {
  _set?: Maybe<Group_Set_Input>;
  where: Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Group_By_PkArgs = {
  _set?: Maybe<Group_Set_Input>;
  pk_columns: Group_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Group_InviteArgs = {
  _inc?: Maybe<Group_Invite_Inc_Input>;
  _set?: Maybe<Group_Invite_Set_Input>;
  where: Group_Invite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Group_Invite_By_PkArgs = {
  _inc?: Maybe<Group_Invite_Inc_Input>;
  _set?: Maybe<Group_Invite_Set_Input>;
  pk_columns: Group_Invite_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Group_MemberArgs = {
  _set?: Maybe<Group_Member_Set_Input>;
  where: Group_Member_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Group_Member_By_PkArgs = {
  _set?: Maybe<Group_Member_Set_Input>;
  pk_columns: Group_Member_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_MatchdayArgs = {
  _inc?: Maybe<Matchday_Inc_Input>;
  _set?: Maybe<Matchday_Set_Input>;
  where: Matchday_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Matchday_By_PkArgs = {
  _inc?: Maybe<Matchday_Inc_Input>;
  _set?: Maybe<Matchday_Set_Input>;
  pk_columns: Matchday_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Personnal_DataArgs = {
  _set?: Maybe<User_Personnal_Data_Set_Input>;
  where: User_Personnal_Data_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateforecastStatusArgs = {
  _set?: Maybe<Forecast_Status_Set_Input>;
  where: Forecast_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateforecastStatusByPkArgs = {
  _set?: Maybe<Forecast_Status_Set_Input>;
  pk_columns: Forecast_Status_Pk_Columns_Input;
};

/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "player_team" */
export type Player_Team = {
  __typename?: 'player_team';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  intlKey: Scalars['String'];
  /** An array relationship */
  teamCups: Array<Team_Cup>;
  /** An aggregated array relationship */
  teamCups_aggregate: Team_Cup_Aggregate;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "player_team" */
export type Player_TeamTeamCupsArgs = {
  distinct_on?: Maybe<Array<Team_Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Cup_Order_By>>;
  where?: Maybe<Team_Cup_Bool_Exp>;
};

/** columns and relationships of "player_team" */
export type Player_TeamTeamCups_AggregateArgs = {
  distinct_on?: Maybe<Array<Team_Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Cup_Order_By>>;
  where?: Maybe<Team_Cup_Bool_Exp>;
};

/** aggregated selection of "player_team" */
export type Player_Team_Aggregate = {
  __typename?: 'player_team_aggregate';
  aggregate?: Maybe<Player_Team_Aggregate_Fields>;
  nodes: Array<Player_Team>;
};

/** aggregate fields of "player_team" */
export type Player_Team_Aggregate_Fields = {
  __typename?: 'player_team_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Player_Team_Max_Fields>;
  min?: Maybe<Player_Team_Min_Fields>;
};

/** aggregate fields of "player_team" */
export type Player_Team_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Player_Team_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "player_team" */
export type Player_Team_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Player_Team_Max_Order_By>;
  min?: Maybe<Player_Team_Min_Order_By>;
};

/** input type for inserting array relation for remote table "player_team" */
export type Player_Team_Arr_Rel_Insert_Input = {
  data: Array<Player_Team_Insert_Input>;
  on_conflict?: Maybe<Player_Team_On_Conflict>;
};

/** Boolean expression to filter rows from the table "player_team". All fields are combined with a logical 'AND'. */
export type Player_Team_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Player_Team_Bool_Exp>>>;
  _not?: Maybe<Player_Team_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Player_Team_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  intlKey?: Maybe<String_Comparison_Exp>;
  teamCups?: Maybe<Team_Cup_Bool_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "player_team" */
export enum Player_Team_Constraint {
  /** unique or primary key constraint */
  PlayerTeamIntlKeyKey = 'player_team_intl_key_key',
  /** unique or primary key constraint */
  PlayerTeamsPkey = 'player_teams_pkey',
}

/** input type for inserting data into table "player_team" */
export type Player_Team_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intlKey?: Maybe<Scalars['String']>;
  teamCups?: Maybe<Team_Cup_Arr_Rel_Insert_Input>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Player_Team_Max_Fields = {
  __typename?: 'player_team_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intlKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "player_team" */
export type Player_Team_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  intlKey?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Player_Team_Min_Fields = {
  __typename?: 'player_team_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intlKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "player_team" */
export type Player_Team_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  intlKey?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "player_team" */
export type Player_Team_Mutation_Response = {
  __typename?: 'player_team_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Player_Team>;
};

/** input type for inserting object relation for remote table "player_team" */
export type Player_Team_Obj_Rel_Insert_Input = {
  data: Player_Team_Insert_Input;
  on_conflict?: Maybe<Player_Team_On_Conflict>;
};

/** on conflict condition type for table "player_team" */
export type Player_Team_On_Conflict = {
  constraint: Player_Team_Constraint;
  update_columns: Array<Player_Team_Update_Column>;
  where?: Maybe<Player_Team_Bool_Exp>;
};

/** ordering options when selecting data from "player_team" */
export type Player_Team_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  intlKey?: Maybe<Order_By>;
  teamCups_aggregate?: Maybe<Team_Cup_Aggregate_Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "player_team" */
export type Player_Team_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "player_team" */
export enum Player_Team_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IntlKey = 'intlKey',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "player_team" */
export type Player_Team_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intlKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "player_team" */
export enum Player_Team_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IntlKey = 'intlKey',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** columns and relationships of "pool" */
export type Pool = {
  __typename?: 'pool';
  /** An object relationship */
  cup: Cup;
  cupId: Scalars['uuid'];
  groupNumber?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** An array relationship */
  matches: Array<Match>;
  /** An aggregated array relationship */
  matches_aggregate: Match_Aggregate;
  /** A computed field, executes function "pool_nmb_groups" */
  nmbGroupsPool?: Maybe<Scalars['bigint']>;
  phase: Pool_Types_Enum;
  /** An object relationship */
  poolsType: Pool_Types;
};

/** columns and relationships of "pool" */
export type PoolMatchesArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** columns and relationships of "pool" */
export type PoolMatches_AggregateArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** aggregated selection of "pool" */
export type Pool_Aggregate = {
  __typename?: 'pool_aggregate';
  aggregate?: Maybe<Pool_Aggregate_Fields>;
  nodes: Array<Pool>;
};

/** aggregate fields of "pool" */
export type Pool_Aggregate_Fields = {
  __typename?: 'pool_aggregate_fields';
  avg?: Maybe<Pool_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Pool_Max_Fields>;
  min?: Maybe<Pool_Min_Fields>;
  stddev?: Maybe<Pool_Stddev_Fields>;
  stddev_pop?: Maybe<Pool_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pool_Stddev_Samp_Fields>;
  sum?: Maybe<Pool_Sum_Fields>;
  var_pop?: Maybe<Pool_Var_Pop_Fields>;
  var_samp?: Maybe<Pool_Var_Samp_Fields>;
  variance?: Maybe<Pool_Variance_Fields>;
};

/** aggregate fields of "pool" */
export type Pool_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pool_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pool" */
export type Pool_Aggregate_Order_By = {
  avg?: Maybe<Pool_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Pool_Max_Order_By>;
  min?: Maybe<Pool_Min_Order_By>;
  stddev?: Maybe<Pool_Stddev_Order_By>;
  stddev_pop?: Maybe<Pool_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Pool_Stddev_Samp_Order_By>;
  sum?: Maybe<Pool_Sum_Order_By>;
  var_pop?: Maybe<Pool_Var_Pop_Order_By>;
  var_samp?: Maybe<Pool_Var_Samp_Order_By>;
  variance?: Maybe<Pool_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "pool" */
export type Pool_Arr_Rel_Insert_Input = {
  data: Array<Pool_Insert_Input>;
  on_conflict?: Maybe<Pool_On_Conflict>;
};

/** aggregate avg on columns */
export type Pool_Avg_Fields = {
  __typename?: 'pool_avg_fields';
  groupNumber?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pool" */
export type Pool_Avg_Order_By = {
  groupNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pool". All fields are combined with a logical 'AND'. */
export type Pool_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Pool_Bool_Exp>>>;
  _not?: Maybe<Pool_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Pool_Bool_Exp>>>;
  cup?: Maybe<Cup_Bool_Exp>;
  cupId?: Maybe<Uuid_Comparison_Exp>;
  groupNumber?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  matches?: Maybe<Match_Bool_Exp>;
  phase?: Maybe<Pool_Types_Enum_Comparison_Exp>;
  poolsType?: Maybe<Pool_Types_Bool_Exp>;
};

/** unique or primary key constraints on table "pool" */
export enum Pool_Constraint {
  /** unique or primary key constraint */
  PoolsPkey = 'pools_pkey',
}

/** input type for incrementing integer column in table "pool" */
export type Pool_Inc_Input = {
  groupNumber?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pool" */
export type Pool_Insert_Input = {
  cup?: Maybe<Cup_Obj_Rel_Insert_Input>;
  cupId?: Maybe<Scalars['uuid']>;
  groupNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  matches?: Maybe<Match_Arr_Rel_Insert_Input>;
  phase?: Maybe<Pool_Types_Enum>;
  poolsType?: Maybe<Pool_Types_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Pool_Max_Fields = {
  __typename?: 'pool_max_fields';
  cupId?: Maybe<Scalars['uuid']>;
  groupNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pool" */
export type Pool_Max_Order_By = {
  cupId?: Maybe<Order_By>;
  groupNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Pool_Min_Fields = {
  __typename?: 'pool_min_fields';
  cupId?: Maybe<Scalars['uuid']>;
  groupNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "pool" */
export type Pool_Min_Order_By = {
  cupId?: Maybe<Order_By>;
  groupNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "pool" */
export type Pool_Mutation_Response = {
  __typename?: 'pool_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Pool>;
};

/** input type for inserting object relation for remote table "pool" */
export type Pool_Obj_Rel_Insert_Input = {
  data: Pool_Insert_Input;
  on_conflict?: Maybe<Pool_On_Conflict>;
};

/** on conflict condition type for table "pool" */
export type Pool_On_Conflict = {
  constraint: Pool_Constraint;
  update_columns: Array<Pool_Update_Column>;
  where?: Maybe<Pool_Bool_Exp>;
};

/** ordering options when selecting data from "pool" */
export type Pool_Order_By = {
  cup?: Maybe<Cup_Order_By>;
  cupId?: Maybe<Order_By>;
  groupNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  matches_aggregate?: Maybe<Match_Aggregate_Order_By>;
  phase?: Maybe<Order_By>;
  poolsType?: Maybe<Pool_Types_Order_By>;
};

/** primary key columns input for table: "pool" */
export type Pool_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "pool" */
export enum Pool_Select_Column {
  /** column name */
  CupId = 'cupId',
  /** column name */
  GroupNumber = 'groupNumber',
  /** column name */
  Id = 'id',
  /** column name */
  Phase = 'phase',
}

/** input type for updating data in table "pool" */
export type Pool_Set_Input = {
  cupId?: Maybe<Scalars['uuid']>;
  groupNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  phase?: Maybe<Pool_Types_Enum>;
};

/** aggregate stddev on columns */
export type Pool_Stddev_Fields = {
  __typename?: 'pool_stddev_fields';
  groupNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pool" */
export type Pool_Stddev_Order_By = {
  groupNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pool_Stddev_Pop_Fields = {
  __typename?: 'pool_stddev_pop_fields';
  groupNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pool" */
export type Pool_Stddev_Pop_Order_By = {
  groupNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pool_Stddev_Samp_Fields = {
  __typename?: 'pool_stddev_samp_fields';
  groupNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pool" */
export type Pool_Stddev_Samp_Order_By = {
  groupNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Pool_Sum_Fields = {
  __typename?: 'pool_sum_fields';
  groupNumber?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pool" */
export type Pool_Sum_Order_By = {
  groupNumber?: Maybe<Order_By>;
};

/** columns and relationships of "pool_types" */
export type Pool_Types = {
  __typename?: 'pool_types';
  comment: Scalars['String'];
  /** An array relationship */
  pools: Array<Pool>;
  /** An aggregated array relationship */
  pools_aggregate: Pool_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "pool_types" */
export type Pool_TypesPoolsArgs = {
  distinct_on?: Maybe<Array<Pool_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Order_By>>;
  where?: Maybe<Pool_Bool_Exp>;
};

/** columns and relationships of "pool_types" */
export type Pool_TypesPools_AggregateArgs = {
  distinct_on?: Maybe<Array<Pool_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Order_By>>;
  where?: Maybe<Pool_Bool_Exp>;
};

/** aggregated selection of "pool_types" */
export type Pool_Types_Aggregate = {
  __typename?: 'pool_types_aggregate';
  aggregate?: Maybe<Pool_Types_Aggregate_Fields>;
  nodes: Array<Pool_Types>;
};

/** aggregate fields of "pool_types" */
export type Pool_Types_Aggregate_Fields = {
  __typename?: 'pool_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Pool_Types_Max_Fields>;
  min?: Maybe<Pool_Types_Min_Fields>;
};

/** aggregate fields of "pool_types" */
export type Pool_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pool_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pool_types" */
export type Pool_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Pool_Types_Max_Order_By>;
  min?: Maybe<Pool_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "pool_types" */
export type Pool_Types_Arr_Rel_Insert_Input = {
  data: Array<Pool_Types_Insert_Input>;
  on_conflict?: Maybe<Pool_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "pool_types". All fields are combined with a logical 'AND'. */
export type Pool_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Pool_Types_Bool_Exp>>>;
  _not?: Maybe<Pool_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Pool_Types_Bool_Exp>>>;
  comment?: Maybe<String_Comparison_Exp>;
  pools?: Maybe<Pool_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pool_types" */
export enum Pool_Types_Constraint {
  /** unique or primary key constraint */
  PoolsTypesPkey = 'pools_types_pkey',
}

export enum Pool_Types_Enum {
  /** 8th finaly */
  EighthFinals = 'eighth_finals',
  /** Only one match left */
  Finale = 'finale',
  /** Group play */
  Group = 'group',
  /** quarter finals */
  QuarterFinals = 'quarter_finals',
  /** Semi finals */
  SemiFinals = 'semi_finals',
}

/** expression to compare columns of type pool_types_enum. All fields are combined with logical 'AND'. */
export type Pool_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Pool_Types_Enum>;
  _in?: Maybe<Array<Pool_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Pool_Types_Enum>;
  _nin?: Maybe<Array<Pool_Types_Enum>>;
};

/** input type for inserting data into table "pool_types" */
export type Pool_Types_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  pools?: Maybe<Pool_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Pool_Types_Max_Fields = {
  __typename?: 'pool_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pool_types" */
export type Pool_Types_Max_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Pool_Types_Min_Fields = {
  __typename?: 'pool_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pool_types" */
export type Pool_Types_Min_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "pool_types" */
export type Pool_Types_Mutation_Response = {
  __typename?: 'pool_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Pool_Types>;
};

/** input type for inserting object relation for remote table "pool_types" */
export type Pool_Types_Obj_Rel_Insert_Input = {
  data: Pool_Types_Insert_Input;
  on_conflict?: Maybe<Pool_Types_On_Conflict>;
};

/** on conflict condition type for table "pool_types" */
export type Pool_Types_On_Conflict = {
  constraint: Pool_Types_Constraint;
  update_columns: Array<Pool_Types_Update_Column>;
  where?: Maybe<Pool_Types_Bool_Exp>;
};

/** ordering options when selecting data from "pool_types" */
export type Pool_Types_Order_By = {
  comment?: Maybe<Order_By>;
  pools_aggregate?: Maybe<Pool_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "pool_types" */
export type Pool_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "pool_types" */
export enum Pool_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "pool_types" */
export type Pool_Types_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "pool_types" */
export enum Pool_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** update columns of table "pool" */
export enum Pool_Update_Column {
  /** column name */
  CupId = 'cupId',
  /** column name */
  GroupNumber = 'groupNumber',
  /** column name */
  Id = 'id',
  /** column name */
  Phase = 'phase',
}

/** aggregate var_pop on columns */
export type Pool_Var_Pop_Fields = {
  __typename?: 'pool_var_pop_fields';
  groupNumber?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pool" */
export type Pool_Var_Pop_Order_By = {
  groupNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pool_Var_Samp_Fields = {
  __typename?: 'pool_var_samp_fields';
  groupNumber?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pool" */
export type Pool_Var_Samp_Order_By = {
  groupNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Pool_Variance_Fields = {
  __typename?: 'pool_variance_fields';
  groupNumber?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pool" */
export type Pool_Variance_Order_By = {
  groupNumber?: Maybe<Order_By>;
};

/** columns and relationships of "profile" */
export type Profile = {
  __typename?: 'profile';
  /** An object relationship */
  cup: Cup;
  cupId: Scalars['uuid'];
  /** An array relationship */
  forecasts: Array<Forecast>;
  /** An aggregated array relationship */
  forecasts_aggregate: Forecast_Aggregate;
  /** An object relationship */
  forecasts_status?: Maybe<Profile_Forecast_Status>;
  /** An array relationship */
  group_members: Array<Group_Member>;
  /** An aggregated array relationship */
  group_members_aggregate: Group_Member_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  points?: Maybe<Profile_Points>;
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** columns and relationships of "profile" */
export type ProfileForecastsArgs = {
  distinct_on?: Maybe<Array<Forecast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Order_By>>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** columns and relationships of "profile" */
export type ProfileForecasts_AggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Order_By>>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** columns and relationships of "profile" */
export type ProfileGroup_MembersArgs = {
  distinct_on?: Maybe<Array<Group_Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Member_Order_By>>;
  where?: Maybe<Group_Member_Bool_Exp>;
};

/** columns and relationships of "profile" */
export type ProfileGroup_Members_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Member_Order_By>>;
  where?: Maybe<Group_Member_Bool_Exp>;
};

/** aggregated selection of "profile" */
export type Profile_Aggregate = {
  __typename?: 'profile_aggregate';
  aggregate?: Maybe<Profile_Aggregate_Fields>;
  nodes: Array<Profile>;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_Fields = {
  __typename?: 'profile_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Profile_Max_Fields>;
  min?: Maybe<Profile_Min_Fields>;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile" */
export type Profile_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Max_Order_By>;
  min?: Maybe<Profile_Min_Order_By>;
};

/** input type for inserting array relation for remote table "profile" */
export type Profile_Arr_Rel_Insert_Input = {
  data: Array<Profile_Insert_Input>;
  on_conflict?: Maybe<Profile_On_Conflict>;
};

/** Boolean expression to filter rows from the table "profile". All fields are combined with a logical 'AND'. */
export type Profile_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Profile_Bool_Exp>>>;
  _not?: Maybe<Profile_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Profile_Bool_Exp>>>;
  cup?: Maybe<Cup_Bool_Exp>;
  cupId?: Maybe<Uuid_Comparison_Exp>;
  forecasts?: Maybe<Forecast_Bool_Exp>;
  forecasts_status?: Maybe<Profile_Forecast_Status_Bool_Exp>;
  group_members?: Maybe<Group_Member_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  points?: Maybe<Profile_Points_Bool_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile" */
export enum Profile_Constraint {
  /** unique or primary key constraint */
  ProfileCupIdUserIdKey = 'profile_cup_id_user_id_key',
  /** unique or primary key constraint */
  ProfilesPkey = 'profiles_pkey',
}

/** columns and relationships of "profile_forecast_status" */
export type Profile_Forecast_Status = {
  __typename?: 'profile_forecast_status';
  in_progress?: Maybe<Scalars['bigint']>;
  miss?: Maybe<Scalars['bigint']>;
  partial?: Maybe<Scalars['bigint']>;
  perfect?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "profile_forecast_status" */
export type Profile_Forecast_Status_Aggregate = {
  __typename?: 'profile_forecast_status_aggregate';
  aggregate?: Maybe<Profile_Forecast_Status_Aggregate_Fields>;
  nodes: Array<Profile_Forecast_Status>;
};

/** aggregate fields of "profile_forecast_status" */
export type Profile_Forecast_Status_Aggregate_Fields = {
  __typename?: 'profile_forecast_status_aggregate_fields';
  avg?: Maybe<Profile_Forecast_Status_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Profile_Forecast_Status_Max_Fields>;
  min?: Maybe<Profile_Forecast_Status_Min_Fields>;
  stddev?: Maybe<Profile_Forecast_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Forecast_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Forecast_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Forecast_Status_Sum_Fields>;
  var_pop?: Maybe<Profile_Forecast_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Forecast_Status_Var_Samp_Fields>;
  variance?: Maybe<Profile_Forecast_Status_Variance_Fields>;
};

/** aggregate fields of "profile_forecast_status" */
export type Profile_Forecast_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Forecast_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile_forecast_status" */
export type Profile_Forecast_Status_Aggregate_Order_By = {
  avg?: Maybe<Profile_Forecast_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Forecast_Status_Max_Order_By>;
  min?: Maybe<Profile_Forecast_Status_Min_Order_By>;
  stddev?: Maybe<Profile_Forecast_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Forecast_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Forecast_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Forecast_Status_Sum_Order_By>;
  var_pop?: Maybe<Profile_Forecast_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Forecast_Status_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Forecast_Status_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Profile_Forecast_Status_Avg_Fields = {
  __typename?: 'profile_forecast_status_avg_fields';
  in_progress?: Maybe<Scalars['Float']>;
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "profile_forecast_status" */
export type Profile_Forecast_Status_Avg_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profile_forecast_status". All fields are combined with a logical 'AND'. */
export type Profile_Forecast_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Profile_Forecast_Status_Bool_Exp>>>;
  _not?: Maybe<Profile_Forecast_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Profile_Forecast_Status_Bool_Exp>>>;
  in_progress?: Maybe<Bigint_Comparison_Exp>;
  miss?: Maybe<Bigint_Comparison_Exp>;
  partial?: Maybe<Bigint_Comparison_Exp>;
  perfect?: Maybe<Bigint_Comparison_Exp>;
  profile_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Profile_Forecast_Status_Max_Fields = {
  __typename?: 'profile_forecast_status_max_fields';
  in_progress?: Maybe<Scalars['bigint']>;
  miss?: Maybe<Scalars['bigint']>;
  partial?: Maybe<Scalars['bigint']>;
  perfect?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "profile_forecast_status" */
export type Profile_Forecast_Status_Max_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Forecast_Status_Min_Fields = {
  __typename?: 'profile_forecast_status_min_fields';
  in_progress?: Maybe<Scalars['bigint']>;
  miss?: Maybe<Scalars['bigint']>;
  partial?: Maybe<Scalars['bigint']>;
  perfect?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "profile_forecast_status" */
export type Profile_Forecast_Status_Min_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "profile_forecast_status" */
export type Profile_Forecast_Status_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** select columns of table "profile_forecast_status" */
export enum Profile_Forecast_Status_Select_Column {
  /** column name */
  InProgress = 'in_progress',
  /** column name */
  Miss = 'miss',
  /** column name */
  Partial = 'partial',
  /** column name */
  Perfect = 'perfect',
  /** column name */
  ProfileId = 'profile_id',
}

/** aggregate stddev on columns */
export type Profile_Forecast_Status_Stddev_Fields = {
  __typename?: 'profile_forecast_status_stddev_fields';
  in_progress?: Maybe<Scalars['Float']>;
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "profile_forecast_status" */
export type Profile_Forecast_Status_Stddev_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Forecast_Status_Stddev_Pop_Fields = {
  __typename?: 'profile_forecast_status_stddev_pop_fields';
  in_progress?: Maybe<Scalars['Float']>;
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "profile_forecast_status" */
export type Profile_Forecast_Status_Stddev_Pop_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Forecast_Status_Stddev_Samp_Fields = {
  __typename?: 'profile_forecast_status_stddev_samp_fields';
  in_progress?: Maybe<Scalars['Float']>;
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "profile_forecast_status" */
export type Profile_Forecast_Status_Stddev_Samp_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Forecast_Status_Sum_Fields = {
  __typename?: 'profile_forecast_status_sum_fields';
  in_progress?: Maybe<Scalars['bigint']>;
  miss?: Maybe<Scalars['bigint']>;
  partial?: Maybe<Scalars['bigint']>;
  perfect?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "profile_forecast_status" */
export type Profile_Forecast_Status_Sum_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Profile_Forecast_Status_Var_Pop_Fields = {
  __typename?: 'profile_forecast_status_var_pop_fields';
  in_progress?: Maybe<Scalars['Float']>;
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "profile_forecast_status" */
export type Profile_Forecast_Status_Var_Pop_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Forecast_Status_Var_Samp_Fields = {
  __typename?: 'profile_forecast_status_var_samp_fields';
  in_progress?: Maybe<Scalars['Float']>;
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "profile_forecast_status" */
export type Profile_Forecast_Status_Var_Samp_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Forecast_Status_Variance_Fields = {
  __typename?: 'profile_forecast_status_variance_fields';
  in_progress?: Maybe<Scalars['Float']>;
  miss?: Maybe<Scalars['Float']>;
  partial?: Maybe<Scalars['Float']>;
  perfect?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "profile_forecast_status" */
export type Profile_Forecast_Status_Variance_Order_By = {
  in_progress?: Maybe<Order_By>;
  miss?: Maybe<Order_By>;
  partial?: Maybe<Order_By>;
  perfect?: Maybe<Order_By>;
};

/** input type for inserting data into table "profile" */
export type Profile_Insert_Input = {
  cup?: Maybe<Cup_Obj_Rel_Insert_Input>;
  cupId?: Maybe<Scalars['uuid']>;
  forecasts?: Maybe<Forecast_Arr_Rel_Insert_Input>;
  group_members?: Maybe<Group_Member_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Profile_Max_Fields = {
  __typename?: 'profile_max_fields';
  cupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "profile" */
export type Profile_Max_Order_By = {
  cupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Min_Fields = {
  __typename?: 'profile_min_fields';
  cupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "profile" */
export type Profile_Min_Order_By = {
  cupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "profile" */
export type Profile_Mutation_Response = {
  __typename?: 'profile_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Profile>;
};

/** input type for inserting object relation for remote table "profile" */
export type Profile_Obj_Rel_Insert_Input = {
  data: Profile_Insert_Input;
  on_conflict?: Maybe<Profile_On_Conflict>;
};

/** on conflict condition type for table "profile" */
export type Profile_On_Conflict = {
  constraint: Profile_Constraint;
  update_columns: Array<Profile_Update_Column>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** ordering options when selecting data from "profile" */
export type Profile_Order_By = {
  cup?: Maybe<Cup_Order_By>;
  cupId?: Maybe<Order_By>;
  forecasts_aggregate?: Maybe<Forecast_Aggregate_Order_By>;
  forecasts_status?: Maybe<Profile_Forecast_Status_Order_By>;
  group_members_aggregate?: Maybe<Group_Member_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  points?: Maybe<Profile_Points_Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: "profile" */
export type Profile_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "profile_points" */
export type Profile_Points = {
  __typename?: 'profile_points';
  /** An object relationship */
  cup?: Maybe<Cup>;
  cupId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['bigint']>;
  totalPoints?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "profile_points" */
export type Profile_Points_Aggregate = {
  __typename?: 'profile_points_aggregate';
  aggregate?: Maybe<Profile_Points_Aggregate_Fields>;
  nodes: Array<Profile_Points>;
};

/** aggregate fields of "profile_points" */
export type Profile_Points_Aggregate_Fields = {
  __typename?: 'profile_points_aggregate_fields';
  avg?: Maybe<Profile_Points_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Profile_Points_Max_Fields>;
  min?: Maybe<Profile_Points_Min_Fields>;
  stddev?: Maybe<Profile_Points_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Points_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Points_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Points_Sum_Fields>;
  var_pop?: Maybe<Profile_Points_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Points_Var_Samp_Fields>;
  variance?: Maybe<Profile_Points_Variance_Fields>;
};

/** aggregate fields of "profile_points" */
export type Profile_Points_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Points_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile_points" */
export type Profile_Points_Aggregate_Order_By = {
  avg?: Maybe<Profile_Points_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Points_Max_Order_By>;
  min?: Maybe<Profile_Points_Min_Order_By>;
  stddev?: Maybe<Profile_Points_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Points_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Points_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Points_Sum_Order_By>;
  var_pop?: Maybe<Profile_Points_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Points_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Points_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Profile_Points_Avg_Fields = {
  __typename?: 'profile_points_avg_fields';
  rank?: Maybe<Scalars['Float']>;
  totalPoints?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "profile_points" */
export type Profile_Points_Avg_Order_By = {
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profile_points". All fields are combined with a logical 'AND'. */
export type Profile_Points_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Profile_Points_Bool_Exp>>>;
  _not?: Maybe<Profile_Points_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Profile_Points_Bool_Exp>>>;
  cup?: Maybe<Cup_Bool_Exp>;
  cupId?: Maybe<Uuid_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profileId?: Maybe<Uuid_Comparison_Exp>;
  rank?: Maybe<Bigint_Comparison_Exp>;
  totalPoints?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Profile_Points_Max_Fields = {
  __typename?: 'profile_points_max_fields';
  cupId?: Maybe<Scalars['uuid']>;
  profileId?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['bigint']>;
  totalPoints?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "profile_points" */
export type Profile_Points_Max_Order_By = {
  cupId?: Maybe<Order_By>;
  profileId?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Points_Min_Fields = {
  __typename?: 'profile_points_min_fields';
  cupId?: Maybe<Scalars['uuid']>;
  profileId?: Maybe<Scalars['uuid']>;
  rank?: Maybe<Scalars['bigint']>;
  totalPoints?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "profile_points" */
export type Profile_Points_Min_Order_By = {
  cupId?: Maybe<Order_By>;
  profileId?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** ordering options when selecting data from "profile_points" */
export type Profile_Points_Order_By = {
  cup?: Maybe<Cup_Order_By>;
  cupId?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profileId?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** select columns of table "profile_points" */
export enum Profile_Points_Select_Column {
  /** column name */
  CupId = 'cupId',
  /** column name */
  ProfileId = 'profileId',
  /** column name */
  Rank = 'rank',
  /** column name */
  TotalPoints = 'totalPoints',
}

/** aggregate stddev on columns */
export type Profile_Points_Stddev_Fields = {
  __typename?: 'profile_points_stddev_fields';
  rank?: Maybe<Scalars['Float']>;
  totalPoints?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "profile_points" */
export type Profile_Points_Stddev_Order_By = {
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Points_Stddev_Pop_Fields = {
  __typename?: 'profile_points_stddev_pop_fields';
  rank?: Maybe<Scalars['Float']>;
  totalPoints?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "profile_points" */
export type Profile_Points_Stddev_Pop_Order_By = {
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Points_Stddev_Samp_Fields = {
  __typename?: 'profile_points_stddev_samp_fields';
  rank?: Maybe<Scalars['Float']>;
  totalPoints?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "profile_points" */
export type Profile_Points_Stddev_Samp_Order_By = {
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Points_Sum_Fields = {
  __typename?: 'profile_points_sum_fields';
  rank?: Maybe<Scalars['bigint']>;
  totalPoints?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "profile_points" */
export type Profile_Points_Sum_Order_By = {
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Profile_Points_Var_Pop_Fields = {
  __typename?: 'profile_points_var_pop_fields';
  rank?: Maybe<Scalars['Float']>;
  totalPoints?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "profile_points" */
export type Profile_Points_Var_Pop_Order_By = {
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Points_Var_Samp_Fields = {
  __typename?: 'profile_points_var_samp_fields';
  rank?: Maybe<Scalars['Float']>;
  totalPoints?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "profile_points" */
export type Profile_Points_Var_Samp_Order_By = {
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Points_Variance_Fields = {
  __typename?: 'profile_points_variance_fields';
  rank?: Maybe<Scalars['Float']>;
  totalPoints?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "profile_points" */
export type Profile_Points_Variance_Order_By = {
  rank?: Maybe<Order_By>;
  totalPoints?: Maybe<Order_By>;
};

/** select columns of table "profile" */
export enum Profile_Select_Column {
  /** column name */
  CupId = 'cupId',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "profile" */
export type Profile_Set_Input = {
  cupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "profile" */
export enum Profile_Update_Column {
  /** column name */
  CupId = 'cupId',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId',
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "cup" */
  cup: Array<Cup>;
  /** fetch aggregated fields from the table: "cup" */
  cupAggregate: Cup_Aggregate;
  /** fetch data from the table: "cup" using primary key columns */
  cupByPk?: Maybe<Cup>;
  /** fetch data from the table: "forecast" */
  forecast: Array<Forecast>;
  /** fetch aggregated fields from the table: "forecast" */
  forecastAggregate: Forecast_Aggregate;
  /** fetch data from the table: "forecast" using primary key columns */
  forecastByPk?: Maybe<Forecast>;
  /** fetch data from the table: "forecast_status" */
  forecastStatus: Array<Forecast_Status>;
  /** fetch aggregated fields from the table: "forecast_status" */
  forecastStatusAggregate: Forecast_Status_Aggregate;
  /** fetch data from the table: "forecast_status" using primary key columns */
  forecastStatusByPk?: Maybe<Forecast_Status>;
  /** fetch data from the table: "forecast_status_points" */
  forecast_status_points: Array<Forecast_Status_Points>;
  /** fetch aggregated fields from the table: "forecast_status_points" */
  forecast_status_points_aggregate: Forecast_Status_Points_Aggregate;
  /** fetch data from the table: "forecast_status_points" using primary key columns */
  forecast_status_points_by_pk?: Maybe<Forecast_Status_Points>;
  /** fetch data from the table: "group" */
  group: Array<Group>;
  /** fetch aggregated fields from the table: "group" */
  group_aggregate: Group_Aggregate;
  /** fetch data from the table: "group" using primary key columns */
  group_by_pk?: Maybe<Group>;
  /** fetch data from the table: "group_invite" */
  group_invite: Array<Group_Invite>;
  /** fetch aggregated fields from the table: "group_invite" */
  group_invite_aggregate: Group_Invite_Aggregate;
  /** fetch data from the table: "group_invite" using primary key columns */
  group_invite_by_pk?: Maybe<Group_Invite>;
  /** fetch data from the table: "group_member" */
  group_member: Array<Group_Member>;
  /** fetch aggregated fields from the table: "group_member" */
  group_member_aggregate: Group_Member_Aggregate;
  /** fetch data from the table: "group_member" using primary key columns */
  group_member_by_pk?: Maybe<Group_Member>;
  /** fetch data from the table: "group_points_and_rank" */
  group_points_and_rank: Array<Group_Points_And_Rank>;
  /** fetch aggregated fields from the table: "group_points_and_rank" */
  group_points_and_rank_aggregate: Group_Points_And_Rank_Aggregate;
  /** fetch data from the table: "group_profil_points_and_rank" */
  group_profil_points_and_rank: Array<Group_Profil_Points_And_Rank>;
  /** fetch aggregated fields from the table: "group_profil_points_and_rank" */
  group_profil_points_and_rank_aggregate: Group_Profil_Points_And_Rank_Aggregate;
  /** fetch data from the table: "match" */
  match: Array<Match>;
  /** fetch aggregated fields from the table: "match" */
  matchAggregate: Match_Aggregate;
  /** fetch data from the table: "match" using primary key columns */
  matchByPk?: Maybe<Match>;
  /** fetch data from the table: "matchday" */
  matchday: Array<Matchday>;
  /** fetch aggregated fields from the table: "matchday" */
  matchday_aggregate: Matchday_Aggregate;
  /** fetch data from the table: "matchday" using primary key columns */
  matchday_by_pk?: Maybe<Matchday>;
  /** fetch data from the table: "player_team" */
  playerTeam: Array<Player_Team>;
  /** fetch aggregated fields from the table: "player_team" */
  playerTeamAggregate: Player_Team_Aggregate;
  /** fetch data from the table: "player_team" using primary key columns */
  playerTeamByPk?: Maybe<Player_Team>;
  /** fetch data from the table: "pool" */
  pool: Array<Pool>;
  /** fetch aggregated fields from the table: "pool" */
  poolAggregate: Pool_Aggregate;
  /** fetch data from the table: "pool" using primary key columns */
  poolByPk?: Maybe<Pool>;
  /** fetch data from the table: "pool_types" */
  poolTypes: Array<Pool_Types>;
  /** fetch aggregated fields from the table: "pool_types" */
  poolTypesAggregate: Pool_Types_Aggregate;
  /** fetch data from the table: "pool_types" using primary key columns */
  poolTypesByPk?: Maybe<Pool_Types>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profileAggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profileByPk?: Maybe<Profile>;
  /** fetch data from the table: "profile_forecast_status" */
  profile_forecast_status: Array<Profile_Forecast_Status>;
  /** fetch aggregated fields from the table: "profile_forecast_status" */
  profile_forecast_status_aggregate: Profile_Forecast_Status_Aggregate;
  /** fetch data from the table: "profile_points" */
  profile_points: Array<Profile_Points>;
  /** fetch aggregated fields from the table: "profile_points" */
  profile_points_aggregate: Profile_Points_Aggregate;
  /** fetch data from the table: "team_cup" */
  teamCup: Array<Team_Cup>;
  /** fetch aggregated fields from the table: "team_cup" */
  teamCupAggregate: Team_Cup_Aggregate;
  /** fetch data from the table: "team_cup" using primary key columns */
  teamCupByPk?: Maybe<Team_Cup>;
  /** fetch data from the table: "used_pseudo" */
  used_pseudo: Array<Used_Pseudo>;
  /** fetch aggregated fields from the table: "used_pseudo" */
  used_pseudo_aggregate: Used_Pseudo_Aggregate;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  userAggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  userByPk?: Maybe<User>;
  /** fetch data from the table: "user_personnal_data" */
  userPersonnalData: Array<User_Personnal_Data>;
  /** fetch aggregated fields from the table: "user_personnal_data" */
  userPersonnalDataAggregate: User_Personnal_Data_Aggregate;
};

/** query root */
export type Query_RootCupArgs = {
  distinct_on?: Maybe<Array<Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cup_Order_By>>;
  where?: Maybe<Cup_Bool_Exp>;
};

/** query root */
export type Query_RootCupAggregateArgs = {
  distinct_on?: Maybe<Array<Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cup_Order_By>>;
  where?: Maybe<Cup_Bool_Exp>;
};

/** query root */
export type Query_RootCupByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootForecastArgs = {
  distinct_on?: Maybe<Array<Forecast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Order_By>>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** query root */
export type Query_RootForecastAggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Order_By>>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** query root */
export type Query_RootForecastByPkArgs = {
  matchId: Scalars['uuid'];
  profileId: Scalars['uuid'];
};

/** query root */
export type Query_RootForecastStatusArgs = {
  distinct_on?: Maybe<Array<Forecast_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Status_Order_By>>;
  where?: Maybe<Forecast_Status_Bool_Exp>;
};

/** query root */
export type Query_RootForecastStatusAggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Status_Order_By>>;
  where?: Maybe<Forecast_Status_Bool_Exp>;
};

/** query root */
export type Query_RootForecastStatusByPkArgs = {
  value: Scalars['String'];
};

/** query root */
export type Query_RootForecast_Status_PointsArgs = {
  distinct_on?: Maybe<Array<Forecast_Status_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Status_Points_Order_By>>;
  where?: Maybe<Forecast_Status_Points_Bool_Exp>;
};

/** query root */
export type Query_RootForecast_Status_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Status_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Status_Points_Order_By>>;
  where?: Maybe<Forecast_Status_Points_Bool_Exp>;
};

/** query root */
export type Query_RootForecast_Status_Points_By_PkArgs = {
  forecast_status: Forecast_Status_Enum;
};

/** query root */
export type Query_RootGroupArgs = {
  distinct_on?: Maybe<Array<Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Order_By>>;
  where?: Maybe<Group_Bool_Exp>;
};

/** query root */
export type Query_RootGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Order_By>>;
  where?: Maybe<Group_Bool_Exp>;
};

/** query root */
export type Query_RootGroup_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootGroup_InviteArgs = {
  distinct_on?: Maybe<Array<Group_Invite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Invite_Order_By>>;
  where?: Maybe<Group_Invite_Bool_Exp>;
};

/** query root */
export type Query_RootGroup_Invite_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Invite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Invite_Order_By>>;
  where?: Maybe<Group_Invite_Bool_Exp>;
};

/** query root */
export type Query_RootGroup_Invite_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootGroup_MemberArgs = {
  distinct_on?: Maybe<Array<Group_Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Member_Order_By>>;
  where?: Maybe<Group_Member_Bool_Exp>;
};

/** query root */
export type Query_RootGroup_Member_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Member_Order_By>>;
  where?: Maybe<Group_Member_Bool_Exp>;
};

/** query root */
export type Query_RootGroup_Member_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootGroup_Points_And_RankArgs = {
  distinct_on?: Maybe<Array<Group_Points_And_Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Points_And_Rank_Order_By>>;
  where?: Maybe<Group_Points_And_Rank_Bool_Exp>;
};

/** query root */
export type Query_RootGroup_Points_And_Rank_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Points_And_Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Points_And_Rank_Order_By>>;
  where?: Maybe<Group_Points_And_Rank_Bool_Exp>;
};

/** query root */
export type Query_RootGroup_Profil_Points_And_RankArgs = {
  distinct_on?: Maybe<Array<Group_Profil_Points_And_Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Profil_Points_And_Rank_Order_By>>;
  where?: Maybe<Group_Profil_Points_And_Rank_Bool_Exp>;
};

/** query root */
export type Query_RootGroup_Profil_Points_And_Rank_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Profil_Points_And_Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Profil_Points_And_Rank_Order_By>>;
  where?: Maybe<Group_Profil_Points_And_Rank_Bool_Exp>;
};

/** query root */
export type Query_RootMatchArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** query root */
export type Query_RootMatchAggregateArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** query root */
export type Query_RootMatchByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootMatchdayArgs = {
  distinct_on?: Maybe<Array<Matchday_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Matchday_Order_By>>;
  where?: Maybe<Matchday_Bool_Exp>;
};

/** query root */
export type Query_RootMatchday_AggregateArgs = {
  distinct_on?: Maybe<Array<Matchday_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Matchday_Order_By>>;
  where?: Maybe<Matchday_Bool_Exp>;
};

/** query root */
export type Query_RootMatchday_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootPlayerTeamArgs = {
  distinct_on?: Maybe<Array<Player_Team_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Player_Team_Order_By>>;
  where?: Maybe<Player_Team_Bool_Exp>;
};

/** query root */
export type Query_RootPlayerTeamAggregateArgs = {
  distinct_on?: Maybe<Array<Player_Team_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Player_Team_Order_By>>;
  where?: Maybe<Player_Team_Bool_Exp>;
};

/** query root */
export type Query_RootPlayerTeamByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootPoolArgs = {
  distinct_on?: Maybe<Array<Pool_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Order_By>>;
  where?: Maybe<Pool_Bool_Exp>;
};

/** query root */
export type Query_RootPoolAggregateArgs = {
  distinct_on?: Maybe<Array<Pool_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Order_By>>;
  where?: Maybe<Pool_Bool_Exp>;
};

/** query root */
export type Query_RootPoolByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootPoolTypesArgs = {
  distinct_on?: Maybe<Array<Pool_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Types_Order_By>>;
  where?: Maybe<Pool_Types_Bool_Exp>;
};

/** query root */
export type Query_RootPoolTypesAggregateArgs = {
  distinct_on?: Maybe<Array<Pool_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Types_Order_By>>;
  where?: Maybe<Pool_Types_Bool_Exp>;
};

/** query root */
export type Query_RootPoolTypesByPkArgs = {
  value: Scalars['String'];
};

/** query root */
export type Query_RootProfileArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** query root */
export type Query_RootProfileAggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** query root */
export type Query_RootProfileByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootProfile_Forecast_StatusArgs = {
  distinct_on?: Maybe<Array<Profile_Forecast_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Forecast_Status_Order_By>>;
  where?: Maybe<Profile_Forecast_Status_Bool_Exp>;
};

/** query root */
export type Query_RootProfile_Forecast_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Forecast_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Forecast_Status_Order_By>>;
  where?: Maybe<Profile_Forecast_Status_Bool_Exp>;
};

/** query root */
export type Query_RootProfile_PointsArgs = {
  distinct_on?: Maybe<Array<Profile_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Points_Order_By>>;
  where?: Maybe<Profile_Points_Bool_Exp>;
};

/** query root */
export type Query_RootProfile_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Points_Order_By>>;
  where?: Maybe<Profile_Points_Bool_Exp>;
};

/** query root */
export type Query_RootTeamCupArgs = {
  distinct_on?: Maybe<Array<Team_Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Cup_Order_By>>;
  where?: Maybe<Team_Cup_Bool_Exp>;
};

/** query root */
export type Query_RootTeamCupAggregateArgs = {
  distinct_on?: Maybe<Array<Team_Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Cup_Order_By>>;
  where?: Maybe<Team_Cup_Bool_Exp>;
};

/** query root */
export type Query_RootTeamCupByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootUsed_PseudoArgs = {
  distinct_on?: Maybe<Array<Used_Pseudo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Used_Pseudo_Order_By>>;
  where?: Maybe<Used_Pseudo_Bool_Exp>;
};

/** query root */
export type Query_RootUsed_Pseudo_AggregateArgs = {
  distinct_on?: Maybe<Array<Used_Pseudo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Used_Pseudo_Order_By>>;
  where?: Maybe<Used_Pseudo_Bool_Exp>;
};

/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** query root */
export type Query_RootUserAggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** query root */
export type Query_RootUserByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootUserPersonnalDataArgs = {
  distinct_on?: Maybe<Array<User_Personnal_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Personnal_Data_Order_By>>;
  where?: Maybe<User_Personnal_Data_Bool_Exp>;
};

/** query root */
export type Query_RootUserPersonnalDataAggregateArgs = {
  distinct_on?: Maybe<Array<User_Personnal_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Personnal_Data_Order_By>>;
  where?: Maybe<User_Personnal_Data_Bool_Exp>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "cup" */
  cup: Array<Cup>;
  /** fetch aggregated fields from the table: "cup" */
  cupAggregate: Cup_Aggregate;
  /** fetch data from the table: "cup" using primary key columns */
  cupByPk?: Maybe<Cup>;
  /** fetch data from the table: "forecast" */
  forecast: Array<Forecast>;
  /** fetch aggregated fields from the table: "forecast" */
  forecastAggregate: Forecast_Aggregate;
  /** fetch data from the table: "forecast" using primary key columns */
  forecastByPk?: Maybe<Forecast>;
  /** fetch data from the table: "forecast_status" */
  forecastStatus: Array<Forecast_Status>;
  /** fetch aggregated fields from the table: "forecast_status" */
  forecastStatusAggregate: Forecast_Status_Aggregate;
  /** fetch data from the table: "forecast_status" using primary key columns */
  forecastStatusByPk?: Maybe<Forecast_Status>;
  /** fetch data from the table: "forecast_status_points" */
  forecast_status_points: Array<Forecast_Status_Points>;
  /** fetch aggregated fields from the table: "forecast_status_points" */
  forecast_status_points_aggregate: Forecast_Status_Points_Aggregate;
  /** fetch data from the table: "forecast_status_points" using primary key columns */
  forecast_status_points_by_pk?: Maybe<Forecast_Status_Points>;
  /** fetch data from the table: "group" */
  group: Array<Group>;
  /** fetch aggregated fields from the table: "group" */
  group_aggregate: Group_Aggregate;
  /** fetch data from the table: "group" using primary key columns */
  group_by_pk?: Maybe<Group>;
  /** fetch data from the table: "group_invite" */
  group_invite: Array<Group_Invite>;
  /** fetch aggregated fields from the table: "group_invite" */
  group_invite_aggregate: Group_Invite_Aggregate;
  /** fetch data from the table: "group_invite" using primary key columns */
  group_invite_by_pk?: Maybe<Group_Invite>;
  /** fetch data from the table: "group_member" */
  group_member: Array<Group_Member>;
  /** fetch aggregated fields from the table: "group_member" */
  group_member_aggregate: Group_Member_Aggregate;
  /** fetch data from the table: "group_member" using primary key columns */
  group_member_by_pk?: Maybe<Group_Member>;
  /** fetch data from the table: "group_points_and_rank" */
  group_points_and_rank: Array<Group_Points_And_Rank>;
  /** fetch aggregated fields from the table: "group_points_and_rank" */
  group_points_and_rank_aggregate: Group_Points_And_Rank_Aggregate;
  /** fetch data from the table: "group_profil_points_and_rank" */
  group_profil_points_and_rank: Array<Group_Profil_Points_And_Rank>;
  /** fetch aggregated fields from the table: "group_profil_points_and_rank" */
  group_profil_points_and_rank_aggregate: Group_Profil_Points_And_Rank_Aggregate;
  /** fetch data from the table: "match" */
  match: Array<Match>;
  /** fetch aggregated fields from the table: "match" */
  matchAggregate: Match_Aggregate;
  /** fetch data from the table: "match" using primary key columns */
  matchByPk?: Maybe<Match>;
  /** fetch data from the table: "matchday" */
  matchday: Array<Matchday>;
  /** fetch aggregated fields from the table: "matchday" */
  matchday_aggregate: Matchday_Aggregate;
  /** fetch data from the table: "matchday" using primary key columns */
  matchday_by_pk?: Maybe<Matchday>;
  /** fetch data from the table: "player_team" */
  playerTeam: Array<Player_Team>;
  /** fetch aggregated fields from the table: "player_team" */
  playerTeamAggregate: Player_Team_Aggregate;
  /** fetch data from the table: "player_team" using primary key columns */
  playerTeamByPk?: Maybe<Player_Team>;
  /** fetch data from the table: "pool" */
  pool: Array<Pool>;
  /** fetch aggregated fields from the table: "pool" */
  poolAggregate: Pool_Aggregate;
  /** fetch data from the table: "pool" using primary key columns */
  poolByPk?: Maybe<Pool>;
  /** fetch data from the table: "pool_types" */
  poolTypes: Array<Pool_Types>;
  /** fetch aggregated fields from the table: "pool_types" */
  poolTypesAggregate: Pool_Types_Aggregate;
  /** fetch data from the table: "pool_types" using primary key columns */
  poolTypesByPk?: Maybe<Pool_Types>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profileAggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profileByPk?: Maybe<Profile>;
  /** fetch data from the table: "profile_forecast_status" */
  profile_forecast_status: Array<Profile_Forecast_Status>;
  /** fetch aggregated fields from the table: "profile_forecast_status" */
  profile_forecast_status_aggregate: Profile_Forecast_Status_Aggregate;
  /** fetch data from the table: "profile_points" */
  profile_points: Array<Profile_Points>;
  /** fetch aggregated fields from the table: "profile_points" */
  profile_points_aggregate: Profile_Points_Aggregate;
  /** fetch data from the table: "team_cup" */
  teamCup: Array<Team_Cup>;
  /** fetch aggregated fields from the table: "team_cup" */
  teamCupAggregate: Team_Cup_Aggregate;
  /** fetch data from the table: "team_cup" using primary key columns */
  teamCupByPk?: Maybe<Team_Cup>;
  /** fetch data from the table: "used_pseudo" */
  used_pseudo: Array<Used_Pseudo>;
  /** fetch aggregated fields from the table: "used_pseudo" */
  used_pseudo_aggregate: Used_Pseudo_Aggregate;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  userAggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  userByPk?: Maybe<User>;
  /** fetch data from the table: "user_personnal_data" */
  userPersonnalData: Array<User_Personnal_Data>;
  /** fetch aggregated fields from the table: "user_personnal_data" */
  userPersonnalDataAggregate: User_Personnal_Data_Aggregate;
};

/** subscription root */
export type Subscription_RootCupArgs = {
  distinct_on?: Maybe<Array<Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cup_Order_By>>;
  where?: Maybe<Cup_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCupAggregateArgs = {
  distinct_on?: Maybe<Array<Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cup_Order_By>>;
  where?: Maybe<Cup_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCupByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootForecastArgs = {
  distinct_on?: Maybe<Array<Forecast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Order_By>>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootForecastAggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Order_By>>;
  where?: Maybe<Forecast_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootForecastByPkArgs = {
  matchId: Scalars['uuid'];
  profileId: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootForecastStatusArgs = {
  distinct_on?: Maybe<Array<Forecast_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Status_Order_By>>;
  where?: Maybe<Forecast_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootForecastStatusAggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Status_Order_By>>;
  where?: Maybe<Forecast_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootForecastStatusByPkArgs = {
  value: Scalars['String'];
};

/** subscription root */
export type Subscription_RootForecast_Status_PointsArgs = {
  distinct_on?: Maybe<Array<Forecast_Status_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Status_Points_Order_By>>;
  where?: Maybe<Forecast_Status_Points_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootForecast_Status_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Forecast_Status_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forecast_Status_Points_Order_By>>;
  where?: Maybe<Forecast_Status_Points_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootForecast_Status_Points_By_PkArgs = {
  forecast_status: Forecast_Status_Enum;
};

/** subscription root */
export type Subscription_RootGroupArgs = {
  distinct_on?: Maybe<Array<Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Order_By>>;
  where?: Maybe<Group_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Order_By>>;
  where?: Maybe<Group_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGroup_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootGroup_InviteArgs = {
  distinct_on?: Maybe<Array<Group_Invite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Invite_Order_By>>;
  where?: Maybe<Group_Invite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGroup_Invite_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Invite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Invite_Order_By>>;
  where?: Maybe<Group_Invite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGroup_Invite_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootGroup_MemberArgs = {
  distinct_on?: Maybe<Array<Group_Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Member_Order_By>>;
  where?: Maybe<Group_Member_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGroup_Member_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Member_Order_By>>;
  where?: Maybe<Group_Member_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGroup_Member_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootGroup_Points_And_RankArgs = {
  distinct_on?: Maybe<Array<Group_Points_And_Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Points_And_Rank_Order_By>>;
  where?: Maybe<Group_Points_And_Rank_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGroup_Points_And_Rank_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Points_And_Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Points_And_Rank_Order_By>>;
  where?: Maybe<Group_Points_And_Rank_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGroup_Profil_Points_And_RankArgs = {
  distinct_on?: Maybe<Array<Group_Profil_Points_And_Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Profil_Points_And_Rank_Order_By>>;
  where?: Maybe<Group_Profil_Points_And_Rank_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGroup_Profil_Points_And_Rank_AggregateArgs = {
  distinct_on?: Maybe<Array<Group_Profil_Points_And_Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Group_Profil_Points_And_Rank_Order_By>>;
  where?: Maybe<Group_Profil_Points_And_Rank_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMatchArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMatchAggregateArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMatchByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootMatchdayArgs = {
  distinct_on?: Maybe<Array<Matchday_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Matchday_Order_By>>;
  where?: Maybe<Matchday_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMatchday_AggregateArgs = {
  distinct_on?: Maybe<Array<Matchday_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Matchday_Order_By>>;
  where?: Maybe<Matchday_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMatchday_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootPlayerTeamArgs = {
  distinct_on?: Maybe<Array<Player_Team_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Player_Team_Order_By>>;
  where?: Maybe<Player_Team_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootPlayerTeamAggregateArgs = {
  distinct_on?: Maybe<Array<Player_Team_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Player_Team_Order_By>>;
  where?: Maybe<Player_Team_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootPlayerTeamByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootPoolArgs = {
  distinct_on?: Maybe<Array<Pool_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Order_By>>;
  where?: Maybe<Pool_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootPoolAggregateArgs = {
  distinct_on?: Maybe<Array<Pool_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Order_By>>;
  where?: Maybe<Pool_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootPoolByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootPoolTypesArgs = {
  distinct_on?: Maybe<Array<Pool_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Types_Order_By>>;
  where?: Maybe<Pool_Types_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootPoolTypesAggregateArgs = {
  distinct_on?: Maybe<Array<Pool_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pool_Types_Order_By>>;
  where?: Maybe<Pool_Types_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootPoolTypesByPkArgs = {
  value: Scalars['String'];
};

/** subscription root */
export type Subscription_RootProfileArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootProfileAggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootProfileByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootProfile_Forecast_StatusArgs = {
  distinct_on?: Maybe<Array<Profile_Forecast_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Forecast_Status_Order_By>>;
  where?: Maybe<Profile_Forecast_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootProfile_Forecast_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Forecast_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Forecast_Status_Order_By>>;
  where?: Maybe<Profile_Forecast_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootProfile_PointsArgs = {
  distinct_on?: Maybe<Array<Profile_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Points_Order_By>>;
  where?: Maybe<Profile_Points_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootProfile_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Points_Order_By>>;
  where?: Maybe<Profile_Points_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTeamCupArgs = {
  distinct_on?: Maybe<Array<Team_Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Cup_Order_By>>;
  where?: Maybe<Team_Cup_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTeamCupAggregateArgs = {
  distinct_on?: Maybe<Array<Team_Cup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Cup_Order_By>>;
  where?: Maybe<Team_Cup_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTeamCupByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootUsed_PseudoArgs = {
  distinct_on?: Maybe<Array<Used_Pseudo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Used_Pseudo_Order_By>>;
  where?: Maybe<Used_Pseudo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUsed_Pseudo_AggregateArgs = {
  distinct_on?: Maybe<Array<Used_Pseudo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Used_Pseudo_Order_By>>;
  where?: Maybe<Used_Pseudo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserAggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootUserPersonnalDataArgs = {
  distinct_on?: Maybe<Array<User_Personnal_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Personnal_Data_Order_By>>;
  where?: Maybe<User_Personnal_Data_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserPersonnalDataAggregateArgs = {
  distinct_on?: Maybe<Array<User_Personnal_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Personnal_Data_Order_By>>;
  where?: Maybe<User_Personnal_Data_Bool_Exp>;
};

/** columns and relationships of "team_cup" */
export type Team_Cup = {
  __typename?: 'team_cup';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  cup: Cup;
  cupId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An array relationship */
  matches: Array<Match>;
  /** An array relationship */
  matchesByTeamHomeId: Array<Match>;
  /** An aggregated array relationship */
  matchesByTeamHomeId_aggregate: Match_Aggregate;
  /** An aggregated array relationship */
  matches_aggregate: Match_Aggregate;
  /** An object relationship */
  playerTeam: Player_Team;
  playerTeamId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "team_cup" */
export type Team_CupMatchesArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** columns and relationships of "team_cup" */
export type Team_CupMatchesByTeamHomeIdArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** columns and relationships of "team_cup" */
export type Team_CupMatchesByTeamHomeId_AggregateArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** columns and relationships of "team_cup" */
export type Team_CupMatches_AggregateArgs = {
  distinct_on?: Maybe<Array<Match_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Order_By>>;
  where?: Maybe<Match_Bool_Exp>;
};

/** aggregated selection of "team_cup" */
export type Team_Cup_Aggregate = {
  __typename?: 'team_cup_aggregate';
  aggregate?: Maybe<Team_Cup_Aggregate_Fields>;
  nodes: Array<Team_Cup>;
};

/** aggregate fields of "team_cup" */
export type Team_Cup_Aggregate_Fields = {
  __typename?: 'team_cup_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Team_Cup_Max_Fields>;
  min?: Maybe<Team_Cup_Min_Fields>;
};

/** aggregate fields of "team_cup" */
export type Team_Cup_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Team_Cup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "team_cup" */
export type Team_Cup_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Team_Cup_Max_Order_By>;
  min?: Maybe<Team_Cup_Min_Order_By>;
};

/** input type for inserting array relation for remote table "team_cup" */
export type Team_Cup_Arr_Rel_Insert_Input = {
  data: Array<Team_Cup_Insert_Input>;
  on_conflict?: Maybe<Team_Cup_On_Conflict>;
};

/** Boolean expression to filter rows from the table "team_cup". All fields are combined with a logical 'AND'. */
export type Team_Cup_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Team_Cup_Bool_Exp>>>;
  _not?: Maybe<Team_Cup_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Team_Cup_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  cup?: Maybe<Cup_Bool_Exp>;
  cupId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  matches?: Maybe<Match_Bool_Exp>;
  matchesByTeamHomeId?: Maybe<Match_Bool_Exp>;
  playerTeam?: Maybe<Player_Team_Bool_Exp>;
  playerTeamId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_cup" */
export enum Team_Cup_Constraint {
  /** unique or primary key constraint */
  TeamCupCupIdPlayerTeamIdKey = 'team_cup_cup_id_player_team_id_key',
  /** unique or primary key constraint */
  TeamCupPkey = 'team_cup_pkey',
}

/** input type for inserting data into table "team_cup" */
export type Team_Cup_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  cup?: Maybe<Cup_Obj_Rel_Insert_Input>;
  cupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  matches?: Maybe<Match_Arr_Rel_Insert_Input>;
  matchesByTeamHomeId?: Maybe<Match_Arr_Rel_Insert_Input>;
  playerTeam?: Maybe<Player_Team_Obj_Rel_Insert_Input>;
  playerTeamId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Team_Cup_Max_Fields = {
  __typename?: 'team_cup_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  cupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  playerTeamId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "team_cup" */
export type Team_Cup_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  cupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  playerTeamId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Cup_Min_Fields = {
  __typename?: 'team_cup_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  cupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  playerTeamId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "team_cup" */
export type Team_Cup_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  cupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  playerTeamId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "team_cup" */
export type Team_Cup_Mutation_Response = {
  __typename?: 'team_cup_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Team_Cup>;
};

/** input type for inserting object relation for remote table "team_cup" */
export type Team_Cup_Obj_Rel_Insert_Input = {
  data: Team_Cup_Insert_Input;
  on_conflict?: Maybe<Team_Cup_On_Conflict>;
};

/** on conflict condition type for table "team_cup" */
export type Team_Cup_On_Conflict = {
  constraint: Team_Cup_Constraint;
  update_columns: Array<Team_Cup_Update_Column>;
  where?: Maybe<Team_Cup_Bool_Exp>;
};

/** ordering options when selecting data from "team_cup" */
export type Team_Cup_Order_By = {
  createdAt?: Maybe<Order_By>;
  cup?: Maybe<Cup_Order_By>;
  cupId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  matchesByTeamHomeId_aggregate?: Maybe<Match_Aggregate_Order_By>;
  matches_aggregate?: Maybe<Match_Aggregate_Order_By>;
  playerTeam?: Maybe<Player_Team_Order_By>;
  playerTeamId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "team_cup" */
export type Team_Cup_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "team_cup" */
export enum Team_Cup_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CupId = 'cupId',
  /** column name */
  Id = 'id',
  /** column name */
  PlayerTeamId = 'playerTeamId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "team_cup" */
export type Team_Cup_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  cupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  playerTeamId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "team_cup" */
export enum Team_Cup_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CupId = 'cupId',
  /** column name */
  Id = 'id',
  /** column name */
  PlayerTeamId = 'playerTeamId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "used_pseudo" */
export type Used_Pseudo = {
  __typename?: 'used_pseudo';
  pseudo?: Maybe<Scalars['String']>;
};

/** aggregated selection of "used_pseudo" */
export type Used_Pseudo_Aggregate = {
  __typename?: 'used_pseudo_aggregate';
  aggregate?: Maybe<Used_Pseudo_Aggregate_Fields>;
  nodes: Array<Used_Pseudo>;
};

/** aggregate fields of "used_pseudo" */
export type Used_Pseudo_Aggregate_Fields = {
  __typename?: 'used_pseudo_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Used_Pseudo_Max_Fields>;
  min?: Maybe<Used_Pseudo_Min_Fields>;
};

/** aggregate fields of "used_pseudo" */
export type Used_Pseudo_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Used_Pseudo_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "used_pseudo" */
export type Used_Pseudo_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Used_Pseudo_Max_Order_By>;
  min?: Maybe<Used_Pseudo_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "used_pseudo". All fields are combined with a logical 'AND'. */
export type Used_Pseudo_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Used_Pseudo_Bool_Exp>>>;
  _not?: Maybe<Used_Pseudo_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Used_Pseudo_Bool_Exp>>>;
  pseudo?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Used_Pseudo_Max_Fields = {
  __typename?: 'used_pseudo_max_fields';
  pseudo?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "used_pseudo" */
export type Used_Pseudo_Max_Order_By = {
  pseudo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Used_Pseudo_Min_Fields = {
  __typename?: 'used_pseudo_min_fields';
  pseudo?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "used_pseudo" */
export type Used_Pseudo_Min_Order_By = {
  pseudo?: Maybe<Order_By>;
};

/** response of any mutation on the table "used_pseudo" */
export type Used_Pseudo_Mutation_Response = {
  __typename?: 'used_pseudo_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Used_Pseudo>;
};

/** ordering options when selecting data from "used_pseudo" */
export type Used_Pseudo_Order_By = {
  pseudo?: Maybe<Order_By>;
};

/** select columns of table "used_pseudo" */
export enum Used_Pseudo_Select_Column {
  /** column name */
  Pseudo = 'pseudo',
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  createdAt: Scalars['timestamptz'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  personnal_data?: Maybe<User>;
  photoUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  profiles: Array<Profile>;
  /** An aggregated array relationship */
  profiles_aggregate: Profile_Aggregate;
  pseudo?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "user" */
export type UserProfilesArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserProfiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  displayName?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firebaseId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  personnal_data?: Maybe<User_Bool_Exp>;
  photoUrl?: Maybe<String_Comparison_Exp>;
  profiles?: Maybe<Profile_Bool_Exp>;
  pseudo?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserFirebaseIdKey = 'user_firebase_id_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint */
  UsersPseudoKey = 'users_pseudo_key',
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  personnal_data?: Maybe<User_Obj_Rel_Insert_Input>;
  photoUrl?: Maybe<Scalars['String']>;
  profiles?: Maybe<Profile_Arr_Rel_Insert_Input>;
  pseudo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  photoUrl?: Maybe<Scalars['String']>;
  pseudo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  pseudo?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  photoUrl?: Maybe<Scalars['String']>;
  pseudo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  pseudo?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "user" */
export type User_Order_By = {
  createdAt?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  personnal_data?: Maybe<User_Order_By>;
  photoUrl?: Maybe<Order_By>;
  profiles_aggregate?: Maybe<Profile_Aggregate_Order_By>;
  pseudo?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** columns and relationships of "user_personnal_data" */
export type User_Personnal_Data = {
  __typename?: 'user_personnal_data';
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  photoUrl?: Maybe<Scalars['String']>;
  pseudo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<User>;
};

/** aggregated selection of "user_personnal_data" */
export type User_Personnal_Data_Aggregate = {
  __typename?: 'user_personnal_data_aggregate';
  aggregate?: Maybe<User_Personnal_Data_Aggregate_Fields>;
  nodes: Array<User_Personnal_Data>;
};

/** aggregate fields of "user_personnal_data" */
export type User_Personnal_Data_Aggregate_Fields = {
  __typename?: 'user_personnal_data_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Personnal_Data_Max_Fields>;
  min?: Maybe<User_Personnal_Data_Min_Fields>;
};

/** aggregate fields of "user_personnal_data" */
export type User_Personnal_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Personnal_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_personnal_data" */
export type User_Personnal_Data_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Personnal_Data_Max_Order_By>;
  min?: Maybe<User_Personnal_Data_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_personnal_data" */
export type User_Personnal_Data_Arr_Rel_Insert_Input = {
  data: Array<User_Personnal_Data_Insert_Input>;
};

/** Boolean expression to filter rows from the table "user_personnal_data". All fields are combined with a logical 'AND'. */
export type User_Personnal_Data_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Personnal_Data_Bool_Exp>>>;
  _not?: Maybe<User_Personnal_Data_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Personnal_Data_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  displayName?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firebaseId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  photoUrl?: Maybe<String_Comparison_Exp>;
  pseudo?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
};

/** input type for inserting data into table "user_personnal_data" */
export type User_Personnal_Data_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  photoUrl?: Maybe<Scalars['String']>;
  pseudo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Personnal_Data_Max_Fields = {
  __typename?: 'user_personnal_data_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  photoUrl?: Maybe<Scalars['String']>;
  pseudo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user_personnal_data" */
export type User_Personnal_Data_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  pseudo?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Personnal_Data_Min_Fields = {
  __typename?: 'user_personnal_data_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  photoUrl?: Maybe<Scalars['String']>;
  pseudo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user_personnal_data" */
export type User_Personnal_Data_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  pseudo?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_personnal_data" */
export type User_Personnal_Data_Mutation_Response = {
  __typename?: 'user_personnal_data_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Personnal_Data>;
};

/** input type for inserting object relation for remote table "user_personnal_data" */
export type User_Personnal_Data_Obj_Rel_Insert_Input = {
  data: User_Personnal_Data_Insert_Input;
};

/** ordering options when selecting data from "user_personnal_data" */
export type User_Personnal_Data_Order_By = {
  createdAt?: Maybe<Order_By>;
  displayName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  pseudo?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
};

/** select columns of table "user_personnal_data" */
export enum User_Personnal_Data_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseId = 'firebaseId',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  Pseudo = 'pseudo',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "user_personnal_data" */
export type User_Personnal_Data_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  photoUrl?: Maybe<Scalars['String']>;
  pseudo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** primary key columns input for table: "user" */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseId = 'firebaseId',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  Pseudo = 'pseudo',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  photoUrl?: Maybe<Scalars['String']>;
  pseudo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseId = 'firebaseId',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  Pseudo = 'pseudo',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type GetAllGamesWithUnknownTeamForAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllGamesWithUnknownTeamForAdminQuery = { __typename?: 'query_root' } & {
  match: Array<
    { __typename?: 'match' } & Pick<Match, 'id' | 'awayScore' | 'homeScore' | 'playedAt' | 'isMatchOver'> & {
        teamAway?: Maybe<
          { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
              playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'> & {
                  teamCups: Array<{ __typename?: 'team_cup' } & Pick<Team_Cup, 'id'>>;
                };
            }
        >;
        teamHome?: Maybe<
          { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
              playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'> & {
                  teamCups: Array<{ __typename?: 'team_cup' } & Pick<Team_Cup, 'id'>>;
                };
            }
        >;
      }
  >;
};

export type GetAllTeamsForAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTeamsForAdminQuery = { __typename?: 'query_root' } & {
  playerTeam: Array<
    { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'> & {
        teamCups: Array<{ __typename?: 'team_cup' } & Pick<Team_Cup, 'id'>>;
      }
  >;
};

export type UpdateMatchTeamForAdminMutationVariables = Exact<{
  matchId: Scalars['uuid'];
  homeTeamId?: Maybe<Scalars['uuid']>;
  awayTeamId?: Maybe<Scalars['uuid']>;
}>;

export type UpdateMatchTeamForAdminMutation = { __typename?: 'mutation_root' } & {
  updateMatch?: Maybe<
    { __typename?: 'match_mutation_response' } & { returning: Array<{ __typename?: 'match' } & Pick<Match, 'id'>> }
  >;
};

export type GetAllGamesForAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllGamesForAdminQuery = { __typename?: 'query_root' } & {
  match: Array<
    { __typename?: 'match' } & Pick<Match, 'id' | 'awayScore' | 'homeScore' | 'playedAt' | 'isMatchOver'> & {
        teamAway?: Maybe<
          { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
              playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
            }
        >;
        teamHome?: Maybe<
          { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
              playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
            }
        >;
      }
  >;
};

export type UpdateMatchScoreForAdminMutationVariables = Exact<{
  matchId: Scalars['uuid'];
  awayScore: Scalars['Int'];
  homeScore: Scalars['Int'];
}>;

export type UpdateMatchScoreForAdminMutation = { __typename?: 'mutation_root' } & {
  updateMatch?: Maybe<
    { __typename?: 'match_mutation_response' } & {
      returning: Array<{ __typename?: 'match' } & Pick<Match, 'awayScore' | 'homeScore'>>;
    }
  >;
};

export type FetchMatchAndUserForecastForPanelQueryVariables = Exact<{
  matchId: Scalars['uuid'];
  profileId: Scalars['uuid'];
}>;

export type FetchMatchAndUserForecastForPanelQuery = { __typename?: 'query_root' } & {
  matchByPk?: Maybe<
    { __typename?: 'match' } & Pick<Match, 'id' | 'awayScore' | 'homeScore' | 'playedAt' | 'isMatchOver'> & {
        forecasts: Array<
          { __typename?: 'forecast' } & Pick<
            Forecast,
            'profileId' | 'matchId' | 'estimatedAway' | 'estimatedHome' | 'status'
          > & {
              forecastsStatus?: Maybe<
                { __typename?: 'forecast_status' } & Pick<Forecast_Status, 'value'> & {
                    forecastStatusPoints?: Maybe<
                      { __typename?: 'forecast_status_points' } & Pick<
                        Forecast_Status_Points,
                        'points' | 'forecast_status'
                      >
                    >;
                  }
              >;
            }
        >;
        teamAway?: Maybe<
          { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
              playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
            }
        >;
        teamHome?: Maybe<
          { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
              playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
            }
        >;
      }
  >;
};

export type MakeUserPronostixMutationVariables = Exact<{
  matchId: Scalars['uuid'];
  awayForecast: Scalars['Int'];
  homeForecast: Scalars['Int'];
}>;

export type MakeUserPronostixMutation = { __typename?: 'mutation_root' } & {
  makeForecast?: Maybe<
    { __typename?: 'MakeForecastOutput' } & {
      forecast?: Maybe<
        { __typename?: 'forecast' } & Pick<
          Forecast,
          'createdAt' | 'updatedAt' | 'estimatedAway' | 'estimatedHome' | 'matchId' | 'status' | 'profileId'
        >
      >;
    }
  >;
};

export type GameLineDataFragment = { __typename?: 'match' } & Pick<
  Match,
  'id' | 'awayScore' | 'homeScore' | 'playedAt' | 'isMatchOver'
> & {
    teamAway?: Maybe<
      { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
          playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
        }
    >;
    teamHome?: Maybe<
      { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
          playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
        }
    >;
    forecasts: Array<
      { __typename?: 'forecast' } & Pick<Forecast, 'matchId' | 'profileId' | 'estimatedAway' | 'estimatedHome'> & {
          forecastsStatus?: Maybe<{ __typename?: 'forecast_status' } & Pick<Forecast_Status, 'value'>>;
        }
    >;
  };

export type GetMatchWithMembersForecastsForGroupQueryVariables = Exact<{
  groupId: Scalars['uuid'];
  matchId: Scalars['uuid'];
}>;

export type GetMatchWithMembersForecastsForGroupQuery = { __typename?: 'query_root' } & {
  matchByPk?: Maybe<
    { __typename?: 'match' } & Pick<Match, 'awayScore' | 'homeScore' | 'isMatchOver' | 'playedAt' | 'id'> & {
        forecasts: Array<{ __typename?: 'forecast' } & ForecastsGroupMembersFragment>;
        teamAway?: Maybe<
          { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
              playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
            }
        >;
        teamHome?: Maybe<
          { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
              playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
            }
        >;
      }
  >;
};

export type GetDataForPersonnalProfileHeaderQueryVariables = Exact<{
  profileId: Scalars['uuid'];
}>;

export type GetDataForPersonnalProfileHeaderQuery = { __typename?: 'query_root' } & {
  profileByPk?: Maybe<
    { __typename?: 'profile' } & Pick<Profile, 'id'> & {
        points?: Maybe<{ __typename?: 'profile_points' } & Pick<Profile_Points, 'profileId' | 'rank' | 'totalPoints'>>;
        user: { __typename?: 'user' } & Pick<User, 'id' | 'pseudo' | 'photoUrl'>;
        forecasts_status?: Maybe<
          { __typename?: 'profile_forecast_status' } & Pick<
            Profile_Forecast_Status,
            'in_progress' | 'miss' | 'partial' | 'perfect' | 'profile_id'
          >
        >;
      }
  >;
};

export type GroupInviteDataFragment = { __typename?: 'group_invite' } & Pick<
  Group_Invite,
  'expiration_date' | 'id' | 'is_disabled' | 'group_id' | 'public_id'
> & { group?: Maybe<{ __typename?: 'group' } & Pick<Group, 'photo_url' | 'name' | 'slug' | 'id'>> };

export type GetGroupAndInfoQueryVariables = Exact<{
  groupSlug: Scalars['String'];
}>;

export type GetGroupAndInfoQuery = { __typename?: 'query_root' } & {
  group: Array<
    { __typename?: 'group' } & Pick<Group, 'id' | 'name' | 'photo_url'> & {
        group_members: Array<{ __typename?: 'group_member' } & Pick<Group_Member, 'profile_id'>>;
        group_members_aggregate: { __typename?: 'group_member_aggregate' } & {
          aggregate?: Maybe<
            { __typename?: 'group_member_aggregate_fields' } & Pick<Group_Member_Aggregate_Fields, 'count'>
          >;
        };
        group_points_and_rank?: Maybe<
          { __typename?: 'group_points_and_rank' } & Pick<
            Group_Points_And_Rank,
            'group_id' | 'rank' | 'total_points' | 'miss' | 'partial' | 'perfect'
          >
        >;
        groupMembersPointsAndRank: Array<
          { __typename?: 'group_profil_points_and_rank' } & Pick<
            Group_Profil_Points_And_Rank,
            'point_user' | 'rank' | 'profile_id' | 'group_id' | 'cup_id'
          > & {
              profile?: Maybe<
                { __typename?: 'profile' } & Pick<Profile, 'id'> & {
                    user: { __typename?: 'user' } & Pick<User, 'id' | 'photoUrl' | 'pseudo'>;
                  }
              >;
            }
        >;
      }
  >;
};

export type GetAllGamesForGroupWithForecastsCountQueryVariables = Exact<{
  groupId: Scalars['uuid'];
  isMatchOver?: Maybe<Scalars['Boolean']>;
  orderBy: Order_By;
}>;

export type GetAllGamesForGroupWithForecastsCountQuery = { __typename?: 'query_root' } & {
  matchday: Array<
    { __typename?: 'matchday' } & Pick<Matchday, 'id' | 'number'> & {
        matches: Array<
          { __typename?: 'match' } & Pick<Match, 'id' | 'awayScore' | 'homeScore' | 'playedAt' | 'isMatchOver'> & {
              teamAway?: Maybe<
                { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
                    playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
                  }
              >;
              teamHome?: Maybe<
                { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
                    playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
                  }
              >;
              forecasts_aggregate: { __typename?: 'forecast_aggregate' } & {
                aggregate?: Maybe<
                  { __typename?: 'forecast_aggregate_fields' } & Pick<Forecast_Aggregate_Fields, 'count'>
                >;
              };
            }
        >;
      }
  >;
  pool: Array<
    { __typename?: 'pool' } & Pick<Pool, 'id' | 'phase'> & {
        matches: Array<
          { __typename?: 'match' } & Pick<Match, 'id' | 'awayScore' | 'homeScore' | 'playedAt' | 'isMatchOver'> & {
              teamAway?: Maybe<
                { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
                    playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
                  }
              >;
              teamHome?: Maybe<
                { __typename?: 'team_cup' } & Pick<Team_Cup, 'id'> & {
                    playerTeam: { __typename?: 'player_team' } & Pick<Player_Team, 'id' | 'intlKey'>;
                  }
              >;
              forecasts_aggregate: { __typename?: 'forecast_aggregate' } & {
                aggregate?: Maybe<
                  { __typename?: 'forecast_aggregate_fields' } & Pick<Forecast_Aggregate_Fields, 'count'>
                >;
              };
            }
        >;
      }
  >;
};

export type GetInvitationForGroupQueryVariables = Exact<{
  groupId: Scalars['uuid'];
}>;

export type GetInvitationForGroupQuery = { __typename?: 'query_root' } & {
  group_invite: Array<{ __typename?: 'group_invite' } & Pick<Group_Invite, 'expiration_date' | 'id' | 'public_id'>>;
};

export type CreateInvitationForGroupMutationVariables = Exact<{
  groupId: Scalars['uuid'];
  nmbDaysOpen: Scalars['Int'];
}>;

export type CreateInvitationForGroupMutation = { __typename?: 'mutation_root' } & {
  createGroupInvitationLink?: Maybe<
    { __typename?: 'CreateGroupInvitationLinkOutput' } & Pick<CreateGroupInvitationLinkOutput, 'invitation_id'>
  >;
};

export type ForecastsGroupMembersFragment = { __typename?: 'forecast' } & Pick<
  Forecast,
  'estimatedAway' | 'estimatedHome' | 'status' | 'matchId' | 'profileId'
> & {
    profile: { __typename?: 'profile' } & Pick<Profile, 'id'> & {
        user: { __typename?: 'user' } & Pick<User, 'pseudo' | 'photoUrl' | 'id'>;
      };
  };

export type EditGroupInfosMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  groupId: Scalars['uuid'];
}>;

export type EditGroupInfosMutation = { __typename?: 'mutation_root' } & {
  updateGroupInfo?: Maybe<
    { __typename?: 'UpdateGroupInfoOutput' } & Pick<UpdateGroupInfoOutput, 'groupId'> & {
        group?: Maybe<{ __typename?: 'group' } & Pick<Group, 'slug' | 'id' | 'name' | 'photo_url'>>;
      }
  >;
};

export type CurrentUserQueryVariables = Exact<{
  firebaseId: Scalars['String'];
}>;

export type CurrentUserQuery = { __typename?: 'query_root' } & {
  user: Array<
    { __typename?: 'user_personnal_data' } & Pick<
      User_Personnal_Data,
      'createdAt' | 'displayName' | 'email' | 'firebaseId' | 'pseudo' | 'photoUrl' | 'updatedAt' | 'id'
    > & {
        user?: Maybe<
          { __typename?: 'user' } & Pick<User, 'id'> & {
              profiles: Array<{ __typename?: 'profile' } & ProfileInfoFragmentFragment>;
            }
        >;
      }
  >;
};

export type ProfileInfoFragmentFragment = { __typename?: 'profile' } & Pick<Profile, 'cupId' | 'id' | 'userId'> & {
    points?: Maybe<
      { __typename?: 'profile_points' } & Pick<Profile_Points, 'cupId' | 'profileId' | 'rank' | 'totalPoints'>
    >;
  };

export type IsPseudoTakenQueryVariables = Exact<{
  pseudo: Scalars['String'];
}>;

export type IsPseudoTakenQuery = { __typename?: 'query_root' } & {
  user: Array<{ __typename?: 'used_pseudo' } & Pick<Used_Pseudo, 'pseudo'>>;
};

export type AcceptInvitationMutationVariables = Exact<{
  invitationId: Scalars['String'];
}>;

export type AcceptInvitationMutation = { __typename?: 'mutation_root' } & {
  acceptInvitation?: Maybe<{ __typename?: 'AcceptInvitationOutput' } & Pick<AcceptInvitationOutput, 'group_id'>>;
};

export type GetUserGroupsByProfileIdQueryVariables = Exact<{
  profileId: Scalars['uuid'];
}>;

export type GetUserGroupsByProfileIdQuery = { __typename?: 'query_root' } & {
  group: Array<
    { __typename?: 'group' } & Pick<Group, 'id' | 'name' | 'slug' | 'photo_url'> & {
        adminMembers: Array<{ __typename?: 'group_member' } & Pick<Group_Member, 'is_admin' | 'id'>>;
        group_points_and_rank?: Maybe<
          { __typename?: 'group_points_and_rank' } & Pick<Group_Points_And_Rank, 'rank' | 'group_id'>
        >;
        group_members_aggregate: { __typename?: 'group_member_aggregate' } & {
          aggregate?: Maybe<
            { __typename?: 'group_member_aggregate_fields' } & Pick<Group_Member_Aggregate_Fields, 'count'>
          >;
        };
      }
  >;
};

export type GetIntergroupRankingQueryVariables = Exact<{ [key: string]: never }>;

export type GetIntergroupRankingQuery = { __typename?: 'query_root' } & {
  group_points_and_rank: Array<{ __typename?: 'group_points_and_rank' } & GroupPointsAndRankForRankingFragment>;
};

export type GetInterMyGroupsRankingQueryVariables = Exact<{
  profileId: Scalars['uuid'];
}>;

export type GetInterMyGroupsRankingQuery = { __typename?: 'query_root' } & {
  group_points_and_rank: Array<{ __typename?: 'group_points_and_rank' } & GroupPointsAndRankForRankingFragment>;
};

export type GroupPointsAndRankForRankingFragment = { __typename?: 'group_points_and_rank' } & Pick<
  Group_Points_And_Rank,
  'group_id' | 'miss' | 'partial' | 'perfect' | 'rank' | 'total_points'
> & { group?: Maybe<{ __typename?: 'group' } & Pick<Group, 'name' | 'id' | 'photo_url'>> };

export type CreateGroupMutationVariables = Exact<{
  cupId: Scalars['uuid'];
  groupName: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
}>;

export type CreateGroupMutation = { __typename?: 'mutation_root' } & {
  registerGroup?: Maybe<{ __typename?: 'RegisterGroupOutput' } & Pick<RegisterGroupOutput, 'groupId'>>;
};

export type DeleteGroupMutationVariables = Exact<{
  groupId: Scalars['uuid'];
}>;

export type DeleteGroupMutation = { __typename?: 'mutation_root' } & {
  delete_group_by_pk?: Maybe<{ __typename?: 'group' } & Pick<Group, 'id'>>;
};

export type DeleteGroupMemberMutationVariables = Exact<{
  groupId: Scalars['uuid'];
  profileId: Scalars['uuid'];
}>;

export type DeleteGroupMemberMutation = { __typename?: 'mutation_root' } & {
  delete_group_member?: Maybe<
    { __typename?: 'group_member_mutation_response' } & {
      returning: Array<{ __typename?: 'group_member' } & Pick<Group_Member, 'group_id'>>;
    }
  >;
};

export type GetGamesForHomeQueryVariables = Exact<{
  profileId: Scalars['uuid'];
}>;

export type GetGamesForHomeQuery = { __typename?: 'query_root' } & {
  next: Array<{ __typename?: 'match' } & GameLineDataFragment>;
  prev: Array<{ __typename?: 'match' } & GameLineDataFragment>;
};

export type GetAllNextGamesQueryVariables = Exact<{
  profileId: Scalars['uuid'];
  isMatchOver?: Maybe<Scalars['Boolean']>;
  orderBy: Order_By;
}>;

export type GetAllNextGamesQuery = { __typename?: 'query_root' } & {
  matchday: Array<
    { __typename?: 'matchday' } & Pick<Matchday, 'id' | 'number'> & {
        matches: Array<{ __typename?: 'match' } & GameLineDataFragment>;
      }
  >;
  pool: Array<
    { __typename?: 'pool' } & Pick<Pool, 'id' | 'phase'> & {
        matches: Array<{ __typename?: 'match' } & GameLineDataFragment>;
      }
  >;
};

export type GetRankingQueryVariables = Exact<{ [key: string]: never }>;

export type GetRankingQuery = { __typename?: 'query_root' } & {
  profile: Array<
    { __typename?: 'profile' } & Pick<Profile, 'id'> & {
        user: { __typename?: 'user' } & Pick<User, 'pseudo' | 'photoUrl' | 'id'>;
        points?: Maybe<{ __typename?: 'profile_points' } & Pick<Profile_Points, 'rank' | 'totalPoints' | 'profileId'>>;
        forecasts_status?: Maybe<
          { __typename?: 'profile_forecast_status' } & Pick<
            Profile_Forecast_Status,
            'profile_id' | 'in_progress' | 'miss' | 'partial' | 'perfect'
          >
        >;
      }
  >;
};

export type SetUserPseudoMutationVariables = Exact<{
  pseudo: Scalars['String'];
  id: Scalars['uuid'];
}>;

export type SetUserPseudoMutation = { __typename?: 'mutation_root' } & {
  registerPseudo?: Maybe<{ __typename?: 'RegisterPseudoOutput' } & Pick<RegisterPseudoOutput, 'profile_id'>>;
};

export const GameLineDataFragmentDoc = gql`
  fragment GameLineData on match {
    id
    awayScore
    homeScore
    teamAway {
      id
      playerTeam {
        id
        intlKey
      }
    }
    teamHome {
      id
      playerTeam {
        id
        intlKey
      }
    }
    playedAt
    isMatchOver
    forecasts(where: { profileId: { _eq: $profileId } }) {
      matchId
      profileId
      estimatedAway
      estimatedHome
      forecastsStatus {
        value
      }
    }
  }
`;
export const GroupInviteDataFragmentDoc = gql`
  fragment groupInviteData on group_invite {
    expiration_date
    id
    is_disabled
    group_id
    public_id
    group {
      photo_url
      name
      slug
      id
    }
  }
`;
export const ForecastsGroupMembersFragmentDoc = gql`
  fragment ForecastsGroupMembers on forecast {
    estimatedAway
    estimatedHome
    status
    matchId
    profileId
    profile {
      id
      user {
        pseudo
        photoUrl
        id
      }
    }
  }
`;
export const ProfileInfoFragmentFragmentDoc = gql`
  fragment ProfileInfoFragment on profile {
    cupId
    id
    userId
    points {
      cupId
      profileId
      rank
      totalPoints
    }
  }
`;
export const GroupPointsAndRankForRankingFragmentDoc = gql`
  fragment groupPointsAndRankForRanking on group_points_and_rank {
    group_id
    miss
    partial
    perfect
    rank
    total_points
    group {
      name
      id
      photo_url
    }
  }
`;
export const GetAllGamesWithUnknownTeamForAdminDocument = gql`
  query GetAllGamesWithUnknownTeamForAdmin {
    match(
      where: { _not: { teamHomeId: { _is_null: false }, _and: { teamAwayId: { _is_null: false } } } }
      order_by: { playedAt: asc }
    ) {
      id
      awayScore
      homeScore
      teamAway {
        id
        playerTeam {
          id
          intlKey
          teamCups {
            id
          }
        }
      }
      teamHome {
        id
        playerTeam {
          id
          intlKey
          teamCups {
            id
          }
        }
      }
      playedAt
      isMatchOver
    }
  }
`;

export function useGetAllGamesWithUnknownTeamForAdminQuery(
  options: Omit<Urql.UseQueryArgs<GetAllGamesWithUnknownTeamForAdminQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetAllGamesWithUnknownTeamForAdminQuery>({
    query: GetAllGamesWithUnknownTeamForAdminDocument,
    ...options,
  });
}
export const GetAllTeamsForAdminDocument = gql`
  query getAllTeamsForAdmin {
    playerTeam {
      id
      intlKey
      teamCups {
        id
      }
    }
  }
`;

export function useGetAllTeamsForAdminQuery(
  options: Omit<Urql.UseQueryArgs<GetAllTeamsForAdminQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetAllTeamsForAdminQuery>({ query: GetAllTeamsForAdminDocument, ...options });
}
export const UpdateMatchTeamForAdminDocument = gql`
  mutation UpdateMatchTeamForAdmin($matchId: uuid!, $homeTeamId: uuid, $awayTeamId: uuid) {
    updateMatch(where: { id: { _eq: $matchId } }, _set: { teamHomeId: $homeTeamId, teamAwayId: $awayTeamId }) {
      returning {
        id
      }
    }
  }
`;

export function useUpdateMatchTeamForAdminMutation() {
  return Urql.useMutation<UpdateMatchTeamForAdminMutation, UpdateMatchTeamForAdminMutationVariables>(
    UpdateMatchTeamForAdminDocument,
  );
}
export const GetAllGamesForAdminDocument = gql`
  query GetAllGamesForAdmin {
    match(order_by: { playedAt: asc }, where: { isMatchOver: { _eq: false } }) {
      id
      awayScore
      homeScore
      teamAway {
        id
        playerTeam {
          id
          intlKey
        }
      }
      teamHome {
        id
        playerTeam {
          id
          intlKey
        }
      }
      playedAt
      isMatchOver
    }
  }
`;

export function useGetAllGamesForAdminQuery(
  options: Omit<Urql.UseQueryArgs<GetAllGamesForAdminQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetAllGamesForAdminQuery>({ query: GetAllGamesForAdminDocument, ...options });
}
export const UpdateMatchScoreForAdminDocument = gql`
  mutation UpdateMatchScoreForAdmin($matchId: uuid!, $awayScore: Int!, $homeScore: Int!) {
    updateMatch(
      where: { id: { _eq: $matchId } }
      _set: { isMatchOver: true, awayScore: $awayScore, homeScore: $homeScore }
    ) {
      returning {
        awayScore
        homeScore
      }
    }
  }
`;

export function useUpdateMatchScoreForAdminMutation() {
  return Urql.useMutation<UpdateMatchScoreForAdminMutation, UpdateMatchScoreForAdminMutationVariables>(
    UpdateMatchScoreForAdminDocument,
  );
}
export const FetchMatchAndUserForecastForPanelDocument = gql`
  query fetchMatchAndUserForecastForPanel($matchId: uuid!, $profileId: uuid!) {
    matchByPk(id: $matchId) {
      id
      awayScore
      homeScore
      playedAt
      isMatchOver
      forecasts(where: { profileId: { _eq: $profileId } }) {
        profileId
        matchId
        estimatedAway
        estimatedHome
        status
        forecastsStatus {
          value
          forecastStatusPoints {
            points
            forecast_status
          }
        }
      }
      teamAway {
        id
        playerTeam {
          id
          intlKey
        }
      }
      teamHome {
        id
        playerTeam {
          id
          intlKey
        }
      }
    }
  }
`;

export function useFetchMatchAndUserForecastForPanelQuery(
  options: Omit<Urql.UseQueryArgs<FetchMatchAndUserForecastForPanelQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<FetchMatchAndUserForecastForPanelQuery>({
    query: FetchMatchAndUserForecastForPanelDocument,
    ...options,
  });
}
export const MakeUserPronostixDocument = gql`
  mutation makeUserPronostix($matchId: uuid!, $awayForecast: Int!, $homeForecast: Int!) {
    makeForecast(awayScore: $awayForecast, homeScore: $homeForecast, matchId: $matchId) {
      forecast {
        createdAt
        updatedAt
        estimatedAway
        estimatedHome
        matchId
        status
        profileId
      }
    }
  }
`;

export function useMakeUserPronostixMutation() {
  return Urql.useMutation<MakeUserPronostixMutation, MakeUserPronostixMutationVariables>(MakeUserPronostixDocument);
}
export const GetMatchWithMembersForecastsForGroupDocument = gql`
  query getMatchWithMembersForecastsForGroup($groupId: uuid!, $matchId: uuid!) {
    matchByPk(id: $matchId) {
      awayScore
      homeScore
      isMatchOver
      playedAt
      id
      forecasts(
        order_by: { forecastsStatus: { forecastStatusPoints: { points: desc_nulls_last } } }
        where: { profile: { group_members: { group_id: { _eq: $groupId } } } }
      ) {
        ...ForecastsGroupMembers
      }
      teamAway {
        id
        playerTeam {
          id
          intlKey
        }
      }
      teamHome {
        id
        playerTeam {
          id
          intlKey
        }
      }
    }
  }
  ${ForecastsGroupMembersFragmentDoc}
`;

export function useGetMatchWithMembersForecastsForGroupQuery(
  options: Omit<Urql.UseQueryArgs<GetMatchWithMembersForecastsForGroupQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetMatchWithMembersForecastsForGroupQuery>({
    query: GetMatchWithMembersForecastsForGroupDocument,
    ...options,
  });
}
export const GetDataForPersonnalProfileHeaderDocument = gql`
  query getDataForPersonnalProfileHeader($profileId: uuid!) {
    profileByPk(id: $profileId) {
      id
      points {
        profileId
        rank
        totalPoints
      }
      user {
        id
        pseudo
        photoUrl
      }
      forecasts_status {
        in_progress
        miss
        partial
        perfect
        profile_id
      }
    }
  }
`;

export function useGetDataForPersonnalProfileHeaderQuery(
  options: Omit<Urql.UseQueryArgs<GetDataForPersonnalProfileHeaderQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetDataForPersonnalProfileHeaderQuery>({
    query: GetDataForPersonnalProfileHeaderDocument,
    ...options,
  });
}
export const GetGroupAndInfoDocument = gql`
  query getGroupAndInfo($groupSlug: String!) {
    group(where: { slug: { _eq: $groupSlug } }) {
      id
      name
      photo_url
      group_members(where: { is_admin: { _eq: true } }) {
        profile_id
      }
      group_members_aggregate {
        aggregate {
          count
        }
      }
      group_points_and_rank {
        group_id
        rank
        total_points
        miss
        partial
        perfect
      }
      groupMembersPointsAndRank(order_by: { rank: asc_nulls_last }) {
        point_user
        rank
        profile_id
        group_id
        cup_id
        profile {
          id
          user {
            id
            photoUrl
            pseudo
          }
        }
      }
    }
  }
`;

export function useGetGroupAndInfoQuery(options: Omit<Urql.UseQueryArgs<GetGroupAndInfoQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetGroupAndInfoQuery>({ query: GetGroupAndInfoDocument, ...options });
}
export const GetAllGamesForGroupWithForecastsCountDocument = gql`
  query GetAllGamesForGroupWithForecastsCount($groupId: uuid!, $isMatchOver: Boolean, $orderBy: order_by!) {
    matchday(order_by: { number: $orderBy }, where: { matches: { isMatchOver: { _eq: $isMatchOver } } }) {
      id
      number
      matches(
        order_by: { playedAt: $orderBy }
        where: {
          isMatchOver: { _eq: $isMatchOver }
          _and: { forecasts: { profile: { group_members: { group_id: { _eq: $groupId } } } } }
        }
      ) {
        id
        awayScore
        homeScore
        teamAway {
          id
          playerTeam {
            id
            intlKey
          }
        }
        teamHome {
          id
          playerTeam {
            id
            intlKey
          }
        }
        playedAt
        isMatchOver
        forecasts_aggregate(where: { profile: { group_members: { group_id: { _eq: $groupId } } } }) {
          aggregate {
            count
          }
        }
      }
    }
    pool(
      order_by: { groupNumber: $orderBy }
      where: { phase: { _neq: group }, matches: { isMatchOver: { _eq: $isMatchOver } } }
    ) {
      id
      phase
      matches(
        order_by: { playedAt: $orderBy }
        where: {
          isMatchOver: { _eq: $isMatchOver }
          _and: { forecasts: { profile: { group_members: { group_id: { _eq: $groupId } } } } }
        }
      ) {
        id
        awayScore
        homeScore
        teamAway {
          id
          playerTeam {
            id
            intlKey
          }
        }
        teamHome {
          id
          playerTeam {
            id
            intlKey
          }
        }
        playedAt
        isMatchOver
        forecasts_aggregate(where: { profile: { group_members: { group_id: { _eq: $groupId } } } }) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export function useGetAllGamesForGroupWithForecastsCountQuery(
  options: Omit<Urql.UseQueryArgs<GetAllGamesForGroupWithForecastsCountQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetAllGamesForGroupWithForecastsCountQuery>({
    query: GetAllGamesForGroupWithForecastsCountDocument,
    ...options,
  });
}
export const GetInvitationForGroupDocument = gql`
  query getInvitationForGroup($groupId: uuid!) {
    group_invite(where: { group_id: { _eq: $groupId }, _and: { is_disabled: { _eq: false } } }) {
      expiration_date
      id
      public_id
    }
  }
`;

export function useGetInvitationForGroupQuery(
  options: Omit<Urql.UseQueryArgs<GetInvitationForGroupQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetInvitationForGroupQuery>({ query: GetInvitationForGroupDocument, ...options });
}
export const CreateInvitationForGroupDocument = gql`
  mutation createInvitationForGroup($groupId: uuid!, $nmbDaysOpen: Int!) {
    createGroupInvitationLink(groupId: $groupId, nmbDaysOpen: $nmbDaysOpen) {
      invitation_id
    }
  }
`;

export function useCreateInvitationForGroupMutation() {
  return Urql.useMutation<CreateInvitationForGroupMutation, CreateInvitationForGroupMutationVariables>(
    CreateInvitationForGroupDocument,
  );
}
export const EditGroupInfosDocument = gql`
  mutation editGroupInfos($name: String, $picture: String, $groupId: uuid!) {
    updateGroupInfo(name: $name, picture: $picture, groupId: $groupId) {
      groupId
      group {
        slug
        id
        name
        photo_url
      }
    }
  }
`;

export function useEditGroupInfosMutation() {
  return Urql.useMutation<EditGroupInfosMutation, EditGroupInfosMutationVariables>(EditGroupInfosDocument);
}
export const CurrentUserDocument = gql`
  query currentUser($firebaseId: String!) {
    user: userPersonnalData(where: { firebaseId: { _eq: $firebaseId } }) {
      createdAt
      displayName
      email
      firebaseId
      pseudo
      photoUrl
      updatedAt
      id
      user {
        id
        profiles {
          ...ProfileInfoFragment
        }
      }
    }
  }
  ${ProfileInfoFragmentFragmentDoc}
`;

export function useCurrentUserQuery(options: Omit<Urql.UseQueryArgs<CurrentUserQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CurrentUserQuery>({ query: CurrentUserDocument, ...options });
}
export const IsPseudoTakenDocument = gql`
  query isPseudoTaken($pseudo: String!) {
    user: used_pseudo(where: { pseudo: { _eq: $pseudo } }) {
      pseudo
    }
  }
`;

export function useIsPseudoTakenQuery(options: Omit<Urql.UseQueryArgs<IsPseudoTakenQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<IsPseudoTakenQuery>({ query: IsPseudoTakenDocument, ...options });
}
export const AcceptInvitationDocument = gql`
  mutation acceptInvitation($invitationId: String!) {
    acceptInvitation(invitationId: $invitationId) {
      group_id
    }
  }
`;

export function useAcceptInvitationMutation() {
  return Urql.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument);
}
export const GetUserGroupsByProfileIdDocument = gql`
  query getUserGroupsByProfileId($profileId: uuid!) {
    group(where: { group_members: { profile_id: { _eq: $profileId } } }) {
      id
      name
      slug
      photo_url
      adminMembers: group_members(where: { is_admin: { _eq: true }, _and: { profile_id: { _eq: $profileId } } }) {
        is_admin
        id
      }
      group_points_and_rank {
        rank
        group_id
      }
      group_members_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export function useGetUserGroupsByProfileIdQuery(
  options: Omit<Urql.UseQueryArgs<GetUserGroupsByProfileIdQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetUserGroupsByProfileIdQuery>({ query: GetUserGroupsByProfileIdDocument, ...options });
}
export const GetIntergroupRankingDocument = gql`
  query getIntergroupRanking {
    group_points_and_rank(order_by: { rank: asc }) {
      ...groupPointsAndRankForRanking
    }
  }
  ${GroupPointsAndRankForRankingFragmentDoc}
`;

export function useGetIntergroupRankingQuery(
  options: Omit<Urql.UseQueryArgs<GetIntergroupRankingQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetIntergroupRankingQuery>({ query: GetIntergroupRankingDocument, ...options });
}
export const GetInterMyGroupsRankingDocument = gql`
  query getInterMyGroupsRanking($profileId: uuid!) {
    group_points_and_rank(
      where: { group: { group_members: { profile_id: { _eq: $profileId } } } }
      order_by: { rank: asc }
    ) {
      ...groupPointsAndRankForRanking
    }
  }
  ${GroupPointsAndRankForRankingFragmentDoc}
`;

export function useGetInterMyGroupsRankingQuery(
  options: Omit<Urql.UseQueryArgs<GetInterMyGroupsRankingQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetInterMyGroupsRankingQuery>({ query: GetInterMyGroupsRankingDocument, ...options });
}
export const CreateGroupDocument = gql`
  mutation createGroup($cupId: uuid!, $groupName: String!, $picture: String) {
    registerGroup(cupId: $cupId, groupName: $groupName, picture: $picture) {
      groupId
    }
  }
`;

export function useCreateGroupMutation() {
  return Urql.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument);
}
export const DeleteGroupDocument = gql`
  mutation deleteGroup($groupId: uuid!) {
    delete_group_by_pk(id: $groupId) {
      id
    }
  }
`;

export function useDeleteGroupMutation() {
  return Urql.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument);
}
export const DeleteGroupMemberDocument = gql`
  mutation deleteGroupMember($groupId: uuid!, $profileId: uuid!) {
    delete_group_member(where: { profile_id: { _eq: $profileId }, _and: { group_id: { _eq: $groupId } } }) {
      returning {
        group_id
      }
    }
  }
`;

export function useDeleteGroupMemberMutation() {
  return Urql.useMutation<DeleteGroupMemberMutation, DeleteGroupMemberMutationVariables>(DeleteGroupMemberDocument);
}
export const GetGamesForHomeDocument = gql`
  query getGamesForHome($profileId: uuid!) {
    next: match(limit: 5, order_by: { playedAt: asc }, where: { isMatchOver: { _eq: false } }) {
      ...GameLineData
    }
    prev: match(limit: 5, order_by: { playedAt: desc }, where: { isMatchOver: { _eq: true } }) {
      ...GameLineData
    }
  }
  ${GameLineDataFragmentDoc}
`;

export function useGetGamesForHomeQuery(options: Omit<Urql.UseQueryArgs<GetGamesForHomeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetGamesForHomeQuery>({ query: GetGamesForHomeDocument, ...options });
}
export const GetAllNextGamesDocument = gql`
  query GetAllNextGames($profileId: uuid!, $isMatchOver: Boolean, $orderBy: order_by!) {
    matchday(order_by: { number: $orderBy }, where: { matches: { isMatchOver: { _eq: $isMatchOver } } }) {
      id
      number
      matches(order_by: { playedAt: $orderBy }, where: { isMatchOver: { _eq: $isMatchOver } }) {
        ...GameLineData
      }
    }
    pool(
      order_by: { groupNumber: $orderBy }
      where: { phase: { _neq: group }, matches: { isMatchOver: { _eq: $isMatchOver } } }
    ) {
      id
      phase
      matches(order_by: { playedAt: $orderBy }, where: { isMatchOver: { _eq: $isMatchOver } }) {
        ...GameLineData
      }
    }
  }
  ${GameLineDataFragmentDoc}
`;

export function useGetAllNextGamesQuery(options: Omit<Urql.UseQueryArgs<GetAllNextGamesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAllNextGamesQuery>({ query: GetAllNextGamesDocument, ...options });
}
export const GetRankingDocument = gql`
  query GetRanking {
    profile(order_by: { points: { rank: asc } }) {
      user {
        pseudo
        photoUrl
        id
      }
      points {
        rank
        totalPoints
        profileId
      }
      id
      forecasts_status {
        profile_id
        in_progress
        miss
        partial
        perfect
      }
    }
  }
`;

export function useGetRankingQuery(options: Omit<Urql.UseQueryArgs<GetRankingQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetRankingQuery>({ query: GetRankingDocument, ...options });
}
export const SetUserPseudoDocument = gql`
  mutation setUserPseudo($pseudo: String!, $id: uuid!) {
    registerPseudo(pseudo: $pseudo) {
      profile_id
    }
  }
`;

export function useSetUserPseudoMutation() {
  return Urql.useMutation<SetUserPseudoMutation, SetUserPseudoMutationVariables>(SetUserPseudoDocument);
}
